@use "../config" as *;
* {
    font-family: $font-primary;
    box-sizing: border-box;
    @extend %p-0;
    @extend %m-0;
}
html {
    font-size: var(--base-font-size);
    scroll-behavior: smooth;
}
body {
    font-size: 1.2rem;
}
figure {
    @extend %m-0;
}
small,
label {
    @extend %font-12;
}
big {
    @extend %font-16;
}
sub,
sup {
    @extend %font-10;
}
h1 {
    @extend %font-36;
}
h2 {
    @extend %font-20;
}
h3 {
    @extend %font-18;
}
h4 {
    @extend %font-16;
}
h5 {
    @extend %font-14;
}
h6 {
    @extend %font-12;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    @extend %mb-2;
}
body {
    font-family: $font-primary, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji"; // with backup fonts
    font-weight: $body-font-weight; // normal font weight for all texts
    line-height: $default-line-height; // default line height for every text elements 
    @extend %font-12;
    @extend %light-grey-bg; // 1
    @extend %secondary-light; // 2
}
a {
    text-decoration: none;
    @extend %secondary-light;
    &:hover {
        text-decoration: none;
    }
}
ul,
ol {
    @extend %pl-3;
}
fieldset {
    border: none;
}
button {
    border: none;
    cursor: pointer;
    background-color: transparent;
    @extend %secondary-light;
    &:focus {
        outline: none;
    }
}
input {
    &:focus-within {
        outline: none;
    }
}
@media screen and (min-width: $tablet-min-width) {
    h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 2.6rem;
    }
    h3 {
        font-size: 2.4rem;
    }
    h4 {
        font-size: 2rem;
    }
    h5 {
        font-size: 1.8rem;
    }
    h6 {
        font-size: 1.6rem;
    }
}