@use '../config/' as *;
.waf-shape-head {
    @extend %mx-2-neg;
    .waf-head,
    .article-head {
        isolation: isolate;
        margin-bottom: var(--space-0);
        @extend %pb-3;
        @extend %relative;
        @extend %px-2;
        .title {
            @extend %white;
        }
        &::before {
            content: "";
            height: calc(100% + var(--breadcrumb-height) + var(--space-4) - .2rem);
            z-index: var(--z-breadcrumb-neg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: var(--window-inner-width);
            @include border(2, accent, 10, bottom);
            @extend %secondary-bg;
            @include bgImg("cssimages/breadcrumb-bg-mob.png");
            @include position(calc((var(--breadcrumb-height) * -1) - var(--space-4)), null, null, calc((var(--container-white-space) * -1)));
        }
    }
    .article-head {
        @extend %px-3;
    }
    &.waf-video-page {
        @extend %relative;
        @extend %p-0;
        &::after {
            content: "";
            height: var(--breadcrumb-height);
            z-index: var(--z-breadcrumb-neg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: var(--window-inner-width);
            @include border(2, accent, 10, bottom);
            @include bgImg("cssimages/breadcrumb-bg-mob.png");
            @include position(calc(var(--breadcrumb-height) * -1), null, null, calc(var(--container-white-space) * -1));
            @extend %secondary-bg;
        }
    }
}
@include mq(col-md) {
    .waf-shape-head {
        .waf-head,
        .article-head {
            padding-inline: 0;
            padding-bottom: var(--space-4);
            &::before {
                left: calc(var(--container-white-space) * -1);
            }
        }
    }
}