@use "./map" as *;
@use "./variables" as *;
@use "./mixins" as *;
@use "./extend" as *;
@each $club-id,
$club-color in $club-colors {
    .club-#{$club-id} {
        @each $key ,
        $value in $club-color {
            $h: nth($value, 1);
            $s: nth($value, 2);
            $l: nth($value, 3);
            --club-#{$key}: hsl(#{$h} #{$s} #{$l});
            --hsl-club-#{$key}: #{$h} #{$s} #{$l};
        }
    }
}
.secondary-header {
    @extend %d-none;
}
// club page common style
.club-page {
    .secondary-header {
        @extend %d-block;
        &.stats-header {
            display: none;
        }
    }
    .waf-team .card-content {
        // @extend %club-secondary-bg;
        @extend %club-secondary-bg;
    }
    .waf-row-club-showcase {
        .article-content {
            @extend %club-secondary-bg-7;
        }
        .dependancy-wrapper .accordion-btn {
            @extend %club-primary-bg;
            &[aria-expanded=true] {
                @extend %club-secondary-bg;
            }
            .btn-text {
                @extend %white;
            }
        }
    }
    .waf-standings {
        .table-row.highlight::after {
            @extend %club-primary-bg-2;
        }
        .table-left .highlight::after {
            translate: unset;
        }
    }
    .waf-listing {
        .article-thumbnail {
            .img-box {
                &:after {
                    background: linear-gradient(180deg, transparent 48.57%, hsl(var(--hsl-club-list-gredient)/.7) 76.42%, hsl(var(--hsl-club-list-gredient)/ 1) 105.47%);
                }
                &::before {
                    background: linear-gradient(180deg, hsl(var(--hsl-club-primary)/0.7) 14.52%, hsl(var(--hsl-club-secondary)/0.9) 104.88%);
                }
            }
        }
    }
    .waf-listing,
    .waf-squad,
    .waf-standings.waf-club-standings {
        .head-tab {
            li {
                border: 0;
            }
            a {
                @extend %club-secondary;
                &:after {
                    @extend %club-primary-bg;
                }
            }
        }
    }
    .waf-showcase .waf-scorestrip {
        .head-tab {
            a {
                @extend %white;
            }
        }
    }
    .waf-showcase .waf-scorestrip,
    .waf-showcase .waf-standings {
        .head-tab {
            li {
                border: 0;
            }
            a {
                &:after {
                    @extend %club-primary-bg;
                }
            }
        }
    }
    .waf-row-club-video,
    .waf-row-club-photo {
        background: linear-gradient(34.03deg, var(--club-primary) -23.26%, var(--club-secondary) 70.05%);
        .waf-listing {
            .head-tab {
                a {
                    @extend %white;
                    &:after {
                        @extend %white-bg;
                        @extend %club-primary;
                    }
                }
            }
        }
    }
    .waf-standings {
        .head-tab a {
            color: var(--club-primary)
        }
    }
    .secondary-header {
        // @extend %club-primary-bg;
        @extend %club-primary-bg;
        .nav-link {
            // @extend %club-primary-bg;
            @extend %club-primary-bg;
            &.active {
                @include bg(white);
                .nav-text {
                    color: var(--club-primary);
                }
            }
            .sub-ul {
                @extend %white-bg-7;
                .nav-text {
                    @extend %club-primary;
                }
                .active {
                    .nav-text {
                        @extend %club-primary;
                    }
                }
            }
        }
    }
    &.sticky {
        .secondary-header {
            .logo {
                @include bgImg("club.png");
            }
        }
    }
}
@include mq(col-md) {
    .club-page {
        .waf-row-club-showcase {
            .dependancy-wrapper .accordion-body {
                background-color: hsl(var(--hsl-club-secondary)/0.6);
                @include backdropBlur(2rem);
            }
        }
        .waf-listing,
        .waf-squad,
        .waf-standings.waf-club-standings {
            .head-tab {
                a {
                    color: var(--white);
                    background-color: var(--club-primary);
                }
            }
        }
        .waf-row-club-video,
        .waf-row-club-photo {
            background: linear-gradient(79.63deg, var(--club-secondary) 24.4%, var(--club-primary) 143.26%);
            .waf-listing {
                .head-tab {
                    a {
                        &:after {
                            color: var(--white);
                            background-color: var(--club-primary);
                        }
                    }
                }
            }
        }
        .waf-showcase {
            .tab-name.active {
                background-color: var(--club-primary);
            }
        }
    }
}