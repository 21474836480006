@use "variables" as *;
@use "mixins" as *;
@use "map" as *;
@use "animation" as *;
$arrow-right-icon: 2rem;
// font sizes start
@for $size from 6 through 60 {
  $font-size: #{calc($size * 0.1rem)};
  @each $font-name,
  $font-data in $font-detail {
    @each $font-alias,
    $font-details in $font-data {
      @each $font-family,
      $font-weight in $font-details {
        %font-#{$size}-#{$font-alias} {
          font-size: $font-size;
          font-weight: $font-weight;
        }
      }
    }
  }
  %font-#{$size} {
    font-size: $font-size;
  }
}
@each $font-name,
$font-data in $font-detail {
  @each $font-alias,
  $font-details in $font-data {
    @each $font-family,
    $font-weight in $font-details {
      %font-#{$font-alias} {
        font-weight: $font-weight;
      }
    }
  }
}
%font-0 {
  font-size: 0;
}
// font sizes end
// how to use 
// @extend %font-14;
// @extend %font-14-pm;
// @extend %font-14-pr;
// @extend %font-14-pb;
//colors start
@each $color-key,
$color-value in $colors {
  @for $i from 1 through 9 {
    %#{$color-key}-#{$i} {
      color: hsl(var(--hsl-#{$color-key}) / #{calc($i / 10)});
    }
    %#{$color-key}-bg-#{$i} {
      background-color: hsl(var(--hsl-#{$color-key}) / #{calc($i / 10)});
    }
  }
  %#{$color-key} {
    color: var(--#{$color-key});
  }
  %#{$color-key}-bg {
    background-color: var(--#{$color-key});
  }
}
%transparent-bg {
  background-color: transparent;
}
// colors end
// you can set opacity value from 1 to 9
// 1 = 0.1 opacity , 9 = .9 opacity
// opacity 1 is by default, no need to specify it.
// eg. color @extend %grey
// check map for $colors in map file and use that key to find name
// eg. color @extend %grey-1
// eg. bg-color @extend %grey-1-bg
// convention used t=top,l=left,b=bottom,r=right,y=block[top-bottom],x=inline[left-right],m=margin,p=padding
%relative {
  position: relative;
}
%absolute {
  position: absolute;
}
// absolute start
%position-y-center {
  @include position-combo(y-center);
}
%position-x-center {
  @include position-combo(x-center);
}
%position-center {
  @include position-combo(center);
}
%position-t-l {
  @include position-combo(tl);
}
%position-t-r {
  @include position-combo(tr);
}
%position-b-l {
  @include position-combo(bl);
}
%position-b-r {
  @include position-combo(br);
}
// absolute end
// border-radius starts
%full-radius {
  border-radius: var(--full-radius);
}
%half-radius {
  border-radius: var(--half-radius);
}
%quarter-radius {
  border-radius: var(--quarter-radius);
}
%circle-radius {
  border-radius: 50%;
}
%rounded-radius {
  border-radius: 50vmax;
}
// border-radius ends
// convention used t=top,l=left,b=bottom,r=right,y=block[top-bottom],x=inline[left-right],m=margin,p=padding
// spacing starts
@each $direction,
$direction-value in $directions {
  @for $space from 0 through $max-space-count {
    %m#{$direction}-#{$space} {
      margin#{$direction-value}: var(--space-#{$space});
    }
    %p#{$direction}-#{$space} {
      padding#{$direction-value}: var(--space-#{$space});
    }
    %m#{$direction}-#{$space}-neg {
      margin#{$direction-value}: var(--space-#{$space}-neg);
    }
    %p#{$direction}-#{$space}-neg {
      padding#{$direction-value}: var(--space-#{$space}-neg);
    }
  }
  %m#{$direction}-auto {
    margin#{$direction-value}: auto;
  }
  %p#{$direction}-auto {
    padding#{$direction-value}: auto;
  }
}
@for $i from 0 through $max-space-count {
  @for $j from 0 through $max-space-count {
    %m-#{$i}-#{$j} {
      margin: var(--space-#{$i}) var(--space-#{$j});
    }
    %p-#{$i}-#{$j} {
      padding: var(--space-#{$i}) var(--space-#{$j});
    }
  }
}
//how to use
// @extend %mt-2; margin-top
// @extend %mx-2; margin-inline
// @extend %my-2; margin-block
// @extend %m-2-4; margin-block-inline
@for $space from 0 through $max-space-count {
  %m-#{$space} {
    margin: var(--space-#{$space});
  }
  %p-#{$space} {
    padding: var(--space-#{$space});
  }
  %gap-#{$space} {
    gap: var(--space-#{$space});
  }
  %m-#{$space}-neg {
    margin: var(--space-#{$space}-neg);
  }
  %p-#{$space}-neg {
    padding: var(--space-#{$space}-neg);
  }
  %gap-#{$space}-neg {
    gap: var(--space-#{$space}-neg);
  }
}
%m-auto {
  margin: auto;
}
%p-auto {
  padding: auto;
}
//how to use
// @extend %m-1
// @extend %p-2
// @extend %m-1-neg
// @extend %p-2-neg
// spacing ends
// For width & height
@for $i from 0 through 10 {
  %h-#{calc($i * 10)} {
    height: calc($i * 10%);
  }
  %w-#{calc($i * 10)} {
    width: calc($i * 10%);
  }
}
%w-25 {
  width: 25%;
}
// text transform start
%uppercase {
  text-transform: uppercase;
}
%lowercase {
  text-transform: lowercase;
}
%capitalize {
  text-transform: capitalize;
}
// text transform end
// text alignment start
%text-center {
  text-align: center;
}
%text-left {
  text-align: left;
}
%text-right {
  text-align: right;
}
// text alignment end
// display default start
%d-none {
  display: none;
}
%d-block {
  display: block;
}
%d-inline-block {
  display: inline-block
}
// display default end
// Flex classes start
%flex {
  @include flex-config(flex);
}
%flex-column {
  @include flex-config(flex, column);
}
@each $justify,
$justify-value in $flex-spacing {
  @each $align,
  $align-value in $flex-spacing {
    %flex#{$justify}#{$align} {
      @include flex-config(flex, null, #{$justify-value}, #{$align-value});
    }
    %flex-column#{$justify}#{$align} {
      @include flex-config(flex, column, #{$justify-value}, #{$align-value});
    }
  }
}
// Flex classes end
// shortform
// 	sa: space-around,
// 	sb: space-between,
// 	se: space-evenly,
// 	fe: flex-end,
// 	fs: flex-start,
// 	c:center,
// 	s:stretch,
// 	u:unset,
// 	n:null
//how to use
// @extend %flex;
// @extend %flex-column;
// @extend %flex-n-c;
// @extend %flex-sb-c;
// @extend %flex-c-fs;
// @extend %flex-column-fs-c;
// @extend %flex-column-c-sb;
// Overflow start
%hidden {
  overflow: hidden;
}
%x-auto {
  overflow-x: auto;
}
%x-scroll {
  overflow-x: scroll;
}
%x-hidden {
  overflow-x: hidden;
}
%y-auto {
  overflow-y: auto;
}
%y-scroll {
  overflow-y: scroll;
}
%y-hidden {
  overflow-y: hidden;
}
// Overflow end
// Default wrapper
%default-wrapper {
  max-width: var(--container-max-width);
  margin-inline: auto;
}
%transition {
  transition: 300ms;
}
%btn-fill {
  @extend %transition;
  @extend %p-2-6;
  @extend %primary-bg;
  @extend %white;
  @extend %font-14-pb;
  @extend %half-radius;
  &:hover {
    @extend %accent-bg;
    @extend %neutral;
  }
  &:disabled,
  &.disabled {
    pointer-events: none;
    @extend %dark-grey-bg-5;
    @extend %black-5;
  }
}
%btn-outline {
  @include border(1, primary);
  @extend %transition;
  @extend %primary;
  @extend %p-2-6;
  @extend %font-12-pb;
  @extend %half-radius;
  &:hover {
    @include border(1, accent);
    @extend %accent-bg;
    @extend %neutral;
  }
  &:disabled,
  &.disabled {
    pointer-events: none;
    @extend %dark-grey-bg-5;
    @extend %black-5;
  }
}
%more-btn {
  &::after {
    width: 2rem;
    height: 2rem;
    @extend %transition;
    @extend %p-0;
    @extend %primary-bg;
    @extend %white;
    @extend %font-12-pb;
    @extend %half-radius;
    @extend %flex-c-c;
    @include icon(chevron-right, 10);
  }
}
%more-btn-position {
  .head-tab {
    @include position(null, 0, -3.5rem, null);
    @extend %p-0;
    a {
      @extend %secondary-light;
      @extend %flex-n-c;
      @extend %gap-1;
      @extend %p-0;
      @extend %font-12;
      @extend %relative;
      @extend %more-btn;
    }
  }
}
%common-article-content {
  .article {
    &-content {
      .item-type-icon {
        @extend %mb-2;
      }
    }
    &-title {
      @extend %mb-3;
    }
    &-meta {
      display: inline-block;
      .meta {
        @extend %font-10;
      }
    }
  }
}
// Card List with More Button at bottom
%common-card-with-more-btn {
  @extend %more-btn-position;
  .waf-body {
    &::after {
      content: "";
      height: 0.1rem;
      @extend %w-100;
      @extend %secondary-light-bg-2;
      @include position(null, 0, 0, null);
    }
  }
  .article {
    &-list {
      @extend %pb-3;
      @extend %mb-8;
      @include card-count(1.2, var(--space-3));
    }
    &-content {
      // used properties instead extend to override mixin
      padding: 0;
      padding-top: var(--space-3);
    }
  }
}
%common-gradient {
  .article {
    &-item {
      &:hover {
        .img-box {
          &::after {
            opacity: 0;
            transition: 1000ms;
          }
          &::before {
            top: 0;
            -webkit-backdrop-filter: blur(0.5rem);
            backdrop-filter: blur(0.5rem);
          }
        }
      }
    }
    &-thumbnail {
      .img-box {
        &::before {
          content: "";
          z-index: var(--z-listing);
          opacity: 1;
          transition: 1000ms;
          @include position(100%, null, null, 0);
          @extend %w-100;
          @extend %h-100;
        }
      }
    }
    &-content {
      z-index: var(--z-listing);
    }
  }
}
%remove-common-gradient {
  .article {
    &-item {
      &:hover {
        .img-box {
          &::after {
            opacity: 1;
            transition: 1000ms;
          }
        }
      }
    }
    &-thumbnail {
      .img-box {
        &::before {
          content: unset;
        }
      }
    }
  }
}
%rhs-card-list {
  @extend %flex-column-c-n;
  @extend %gap-3;
  @include listing-card(rhs);
  .article {
    &-content {
      .item-type-icon {
        @extend %secondary-light;
      }
    }
    &-title {
      @include truncate(2, 12, null);
    }
  }
}
%flex-wrap {
  flex-wrap: wrap;
}
%flex-nowrap {
  flex-wrap: nowrap;
}
%subsitution {
  background-image: linear-gradient(95deg, var(--error) 50%, var(--success) 50%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
%second-yellow {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @include bgImg("svg/second-yellow.svg");
}
%stats-more {
  @extend %text-center;
  @extend %p-1-2;
  @extend %white-bg;
  @extend %half-radius;
  &::after {
    @include icon(chevron-right);
  }
}
%fit-line-height {
  line-height: 1em;
}