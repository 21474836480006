@use "../config" as *;
.waf-component {
    &.quicklink-section {
        @extend %p-0;
        @extend %mb-0;
    }
}
.quicklink-section {
    z-index: var(--z-strip);
    @extend %white-bg;
    @extend %w-100;
    @include position-combo(bl, null, null, fixed);
    .quicklink {
        &-list {
            height: var(--quicklink-body-height);
            @extend %flex-n-c;
        }
        &-item {
            @extend %h-100;
            @extend %relative;
            @extend %px-4;
            @extend %flex-sb-c;
            @extend %secondary-dark-bg-2;
            &:nth-child(even) {
                @extend %secondary-dark-bg-1;
            }
            &::before {
                width: 3rem;
                height: 3rem;
                line-height: 1;
                @extend %transition;
                @extend %secondary-dark-bg-2;
                @extend %flex-c-c;
                @extend %circle-radius;
            }
            &-videos::before {
                @include icon(play, 12);
            }
            &-news::before {
                @include icon(article, 12);
            }
            &-fixtures::before {
                @include icon(calander, 12);
            }
            &-points::before {
                @include icon(rank, 12);
            }
            &-fantasy::before {
                @include icon(fantasy, 12);
            }
        }
        &-text {
            white-space: nowrap;
            @extend %capitalize;
            @extend %pl-3;
            @extend %secondary-dark;
            @extend %font-14-pb;
        }
        &-accordion {
            [aria-expanded="true"] {
                bottom: var(--quicklink-body-height);
                z-index: var(--z-open-strip);
                ~ .accordion-body {
                    bottom: 0;
                }
                &::after {
                    @include icon(double-chevron-down, 12);
                }
            }
        }
    }
    .accordion {
        &-title {
            @extend %d-none;
            @extend %uppercase;
            @extend %font-18-pb;
        }
        &-body {
            position: absolute;
            bottom: calc(var(--quicklink-body-height)*-1);
            @extend %w-100;
            @extend %x-auto;
            @extend %white-bg;
            @extend %transition;
        }
        &-btn {
            position: absolute;
            bottom: 0;
            height: var(--quicklink-head-height);
            z-index: var(--z-strip);
            @extend %transition;
            @extend %relative;
            @extend %px-4;
            @extend %flex-sb-c;
            @extend %primary-bg;
            @extend %w-100;
            &::before {
                content: "What are you looking for?";
                @extend %font-12-pb;
                @extend %uppercase;
                @extend %white;
            }
            &::after {
                @extend %white;
                @include icon(double-chevron-up, 12);
                animation: opennow 2s alternate infinite;
            }
            .btn-text {
                @extend %font-0;
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .quicklink-section {
        .quicklink {
            &-item {
                justify-content: center;
                flex: 1;
                &::before {
                    width: 4rem;
                    height: 4rem;
                    font-size: 1.4rem;
                }
                &:hover {
                    &::before {
                        background-image: linear-gradient(130deg, var(--secondary-dark) 17.37%, var(--primary) 100%);
                        @include color(white);
                    }
                    .quicklink-text {
                        background: -webkit-linear-gradient(315deg, var(--secondary-dark) 17.37%, var(--primary) 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
            &-list {
                width: 60%;
                overflow-x: auto;
            }
            &-accordion {
                [aria-expanded="true"] {
                    &::before {
                        content: "hide";
                    }
                }
            }
        }
        .accordion {
            &-title {
                width: 40%;
                margin-bottom: 0;
                background: -webkit-linear-gradient(315deg, var(--secondary-dark) 17.37%, var(--primary) 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                @include flex-config(flex, null, center, center);
            }
            &-body {
                box-shadow: 0 0 .4rem .4rem hsl(var(--hsl-black)/.1);
                @include flex-config(flex, null, null, center);
            }
            &-btn {
                left: 4rem;
                width: max-content;
                border-radius: var(--half-radius) var(--half-radius) 0 0;
                .btn-text {
                    padding-right: var(--space-4);
                }
            }
        }
    }
}
@media (min-width: $desktop-min-width) {
    .quicklink-section {
        left: unset;
        max-width: calc(var(--container-max-width) + 5rem);
        inset-inline: calc(var(--container-white-space) - 2.5rem);
        .quicklink-list {
            width: 70%;
        }
        .accordion {
            &-title {
                width: 30%;
            }
            &-btn {
                left: var(--space-4);
            }
            &-body {
                border-radius: var(--half-radius);
            }
        }
    }
}