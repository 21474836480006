@use "variables" as *;
@use "map" as *;
// shimmer
@mixin shimmer($width: null, $height: null, $radius: 50vmax) {
  width: $width;
  height: $height;
  display: block;
  font-size: 0;
  background: var(--shimmer-light);
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    background-image: linear-gradient(-45deg,
        var(--shimmer-light) 0%,
        var(--shimmer-light) 40%,
        var(--shimmer-dark) 50%,
        var(--shimmer-light) 60%,
        var(--shimmer-light) 100%);
    position: absolute;
    inset: -150%;
    transform: translateX(-100%);
    animation: shimmerAnimation 2s linear 0s infinite normal forwards;
  }
}
// how to use
// @include shimmer()
// @include shimmer(100,20)
// @include shimmer(10,30,var(--space-2))
@mixin flex-config($display,
  $flex-direction: null,
  $justify-content: null,
  $align-items: null) {
  display: $display;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}
// how to use
// @include flex-config(); // just flex
// @include flex-config(flex,column);
// @include flex-config(flex,null,center,center);
@mixin position-combo($combo: null, $x: null, $y: null, $position: absolute) {
  position: $position;
  @if $combo =="tl" {
    top: 0;
    left: 0;
  }
  @else if $combo =="tr" {
    top: 0;
    right: 0;
  }
  @else if $combo =="bl" {
    bottom: 0;
    left: 0;
  }
  @else if $combo =="br" {
    right: 0;
    bottom: 0;
  }
  @else if $combo =="inset" {
    inset: 0;
  }
  @else if $combo=="center" {
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }
  @else if $combo=="y-center" {
    top: 50%;
    translate: 0 -50%;
    right: $x;
    left: $y;
  }
  @else if $combo=="x-center" {
    left: 50%;
    translate: -50% 0;
    top: $x;
    bottom: $y;
  }
}
// @include position-combo(tl); // top == left == 0
// @include position-combo(center);
@mixin position($top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $position: absolute) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  position: $position;
}
@mixin card-count($count, $gap: 0rem, $wrap: nowrap) {
  @include flex-config(flex, row);
  @if $gap !=0rem {
    gap: $gap;
  }
  @if $count % 1==0 {
    @if $wrap ==wrap {
      flex-wrap: wrap;
      overflow-x: hidden;
    }
    @else if $wrap ==nowrap {
      overflow-x: auto;
    }
    & > * {
      width: calc(100% / $count - (($count - 1) * $gap / $count));
      flex-shrink: 0;
    }
  }
  @else {
    overflow-x: auto;
    & > * {
      width: calc(100% / $count - ($count / ceil($count) * $gap));
      flex-shrink: 0;
    }
  }
}
// how to use
// @include card-count(1);
// @include card-count(2,var(--space-4));
// @include card-count(2.5,var(--space-4));
@mixin bg($color, $opacity: 10) {
  $color-hsla: hsl(var(--hsl-#{$color}) / calc($opacity * 0.1));
  background-color: $color-hsla;
}
@mixin color($color, $opacity: 10) {
  $color-hsla: hsl(var(--hsl-#{$color}) / calc($opacity * 0.1));
  color: $color-hsla;
}
// how to use
// @include bg(white,7) // color with opacity
// @include bg(black) // solid color
@mixin dropdown($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    pointer-events: none;
    transition: clip-path $animation-delay linear;
  }
  @else if $status ==open {
    pointer-events: all;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@mixin dropup($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    transition: clip-path $animation-delay linear;
  }
  @else if $status ==open {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@mixin slide-left($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);
    transition: clip-path $animation-delay linear;
  }
  @else if $status ==open {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@mixin slide-right($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    transition: clip-path $animation-delay linear;
  }
  @else if $status ==open {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
}
@mixin circle-slide-right($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: circle(0.4% at 0 100%);
    transition: clip-path $animation-delay linear;
  }
  @else if $status ==open {
    clip-path: circle(141.4% at 0 100%);
  }
}
@mixin circle-slide-left($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: circle(0.5% at 100% 100%);
    transition: clip-path $animation-delay linear;
  }
  @else if $status ==open {
    clip-path: circle(141.5% at 100% 100%);
  }
}
@mixin circle-slide-center($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: circle(0.0% at 50% 50%);
    transition: clip-path $animation-delay linear;
  }
  @else if $status ==open {
    clip-path: circle(100.0% at 50% 50%);
  }
}
//Circle clip path animation starts
@mixin circle-slide($direction, $status, $animation-delay: 700ms) {
  $valid-status-values: (
    close,
    open
  );
@if not index($valid-status-values, $status) {
  @error "Invalid status value: #{$status}. Please use 'close' or 'open'.";
}
transition: clip-path $animation-delay linear;
$clip-path-values: map-get($clip-paths-circle, $direction);
clip-path: circle(nth($clip-path-values, if($status ==close, 1, 2)));
}
//Circle clip path animation ends
/*HOW TO USE
@include circle-slide(right, open);
@include circle-slide(left, open);
@include circle-slide(center, open);
@include circle-slide(right, close);
@include circle-slide(left, close);
@include circle-slide(center, close);

Here, the 'if-not' statement is used for conditional handling if the status doesn't contain the value which is either close or open; then it will throw an error.
Also, the if condition used in the above code has syntax as 'if(condition, true then, false then)'
*/
@mixin circle-grow($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: circle(0.4% at 50% 50%);
    transition: clip-path $animation-delay linear;
  }
  @else if $status ==open {
    clip-path: circle(70.7% at 50% 50%);
  }
}
@mixin fade-in($status, $animation-delay: 300ms) {
  @if $status ==close {
    opacity: 0;
    pointer-events: none;
    transition: opacity $animation-delay linear;
  }
  @else if $status ==open {
    pointer-events: inherit;
    opacity: 1;
  }
}
@mixin custom-scroll {
  scrollbar-color: var(--dark-grey) hsl(var(--hsl-white) / 0.1);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    background: hsl(var(--hsl-white) / 0.1);
    border-radius: var(--half-radius);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: var(--half-radius);
    background: var(--dark-grey);
  }
  &::-webkit-scrollbar-thumb:hover {
    border-radius: var(--half-radius);
    background: var(--black);
  }
}
@mixin truncate($line-number,
  $font-size,
  $line-height: null,
  $font-family: null) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: $line-number;
  font-family: $font-family;
  font-size: $font-size * 0.1rem;
  @if $line-height !=null {
    line-height: $line-height * 0.1rem;
  }
  @else if $line-height ==null {
    $line-height: $font-size * 1.6;
  }
  height: calc($line-number * $line-height * 0.1rem);
}
// how to use
// @include truncate(line number, font-size,line-height, font-family)
// @include truncate(3, 20)
// @include truncate(3, 20,34,$font-pr)
@mixin truncate-text($line-number, $height: auto, $update: false) {
  @if $update ==false {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  -webkit-line-clamp: $line-number;
  @if $height !=auto {
    height: $height * 0.1rem;
  }
}
@mixin grid($count, $gap: var(--space-2), $rows: 1) {
  display: grid;
  grid-template-rows: repeat($rows, 1fr);
  grid-template-columns: repeat($count, 1fr);
  gap: $gap;
}
// how to use
// @include grid(count of columns , space, count of rows);
// @include grid(3,var(--space-2);
// @include grid(3,3rem);
// @include grid(3,3rem,2);
// map
// change map as per project
@mixin icon($name, $size: null, $line-height: null) {
  $icon: map-get($icons, $name);
  font-family: $font-icon;
  @if $icon !=null {
    content: $icon;
    font-weight: 400;
    @if $size !=null {
      font-size: $size * 0.1rem;
    }
    @if $line-height !=null {
      line-height: $line-height * 0.1rem;
    }
  }
  @else {
    @error "Icon '#{$name}' not found in $icons map.";
  }
}
// how to use
// @include icon(arrow-down)
// @include icon(arrow-down,14,null)
@mixin border($width: 1,
  $color: null,
  $opacity: 10,
  $direction: all,
  $style: solid) {
  $opacity-decimal: calc($opacity / 10);
  $color-hsla: hsl(var(--hsl-#{$color}) / $opacity-decimal);
  @if $direction ==all {
    border: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction ==left {
    border-left: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction ==right {
    border-right: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction ==top {
    border-top: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction ==bottom {
    border-bottom: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction ==y {
    border-block: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction ==x {
    border-inline: calc($width * 0.1rem) $style $color-hsla;
  }
  @else {
    @error "Invalid direction #{$direction}, must be one of: all, left, right, top, bottom.";
  }
}
// how to use
// @include border(1,c-purple);
// @include border(1,c-purple,3,left,dotted);
// @include border(border-width,border-color,border-color-opacity,border-position,border-style);
// ***** color name is got through map name so use same name as used in map
@mixin overlay {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, var(--black), transparent);
    pointer-events: none;
    z-index: var(--zindex-overlay);
  }
}
// how to use
// use this inside that element where we need to add overlay
// @include overlay()
@mixin listing-card($type: card, $width: 16rem) {
  @if $type==rhs {
    .article {
      &-list {
        @include flex-config(flex, column, null, center);
      }
      &-wrap {
        @include flex-config(flex, row, null, stretch);
      }
      &-thumbnail {
        width: $width;
        margin-bottom: 0;
        flex-shrink: 0;
        &::before {
          content: unset;
        }
        .img-box {
          border-radius: var(--half-radius) 0 0 var(--half-radius);
          &::after {
            height: 100%;
            @include custom-linear-gradient(hsl(var(--hsl-secondary-medium) / 0.67),
              hsl(var(--hsl-secondary-medium) / 0.88));
          }
        }
      }
      &-item {
        @include mq(col-md) {
          &:hover {
            .article-content {
              &::before {
                opacity: 0;
              }
            }
            .article-title,
            .article-meta,
            .item-type-icon {
              color: hsl(var(--hsl-secondary-light) / 1);
            }
          }
        }
      }
      &-content {
        pointer-events: unset;
        padding: var(--space-2);
        background-color: var(--white);
        width: calc(100% - $width);
        position: relative;
        .item-type-icon {
          position: static;
          margin-bottom: var(--space-1);
          &::before {
            font-size: 1.6rem;
          }
        }
      }
      &-title {
        margin-bottom: var(--space-2);
      }
    }
  }
  @if $type==overlay {
    .article {
      &-item {
        &:hover {
          .article-content {
            &::before {
              opacity: 0;
            }
          }
          .article-title,
          .article-meta,
          .item-type-icon {
            color: inherit;
          }
        }
      }
      &-wrap {
        position: relative;
      }
      &-content {
        width: 100%;
        padding-inline: var(--space-3);
        color: var(--white);
        background-color: transparent;
        pointer-events: none;
        @include position(null, null, 1.5rem, 0);
        a {
          color: var(--white);
        }
        .item-type-icon {
          position: static;
        }
        .timestamp {
          color: inherit;
        }
      }
      &-thumbnail {
        width: 100%;
        margin-bottom: var(--space-0);
        .img-box {
          border-radius: var(--half-radius);
          &::after {
            height: 100%;
            @include custom-linear-gradient(hsl(var(--hsl-secondary-light) / 0.9),
              hsl(var(--hsl-secondary-light) / 1),
              33.57%,
              76.42%,
              105.47%);
          }
        }
      }
      &-title {
        @include truncate(3, 16, 22, $font-primary);
      }
      &-meta {
        .meta {
          color: hsl(var(--hsl-white) / 0.8);
        }
      }
    }
  }
  @if $type==card {
    .article {
      &-item {
        &:hover {
          .article-content {
            &::before {
              opacity: 1;
            }
          }
          .article-title,
          .article-meta,
          .item-type-icon {
            color: var(--white);
          }
        }
      }
      &-thumbnail {
        width: 100%;
        .img-box {
          position: relative;
          border-radius: var(--half-radius) var(--half-radius) 0 0;
          &::after {
            content: "";
            width: 100%;
            height: 70%;
            @include custom-linear-gradient(hsl(var(--hsl-secondary-light) / 0.9),
              hsl(var(--hsl-secondary-light) / 1),
              46.75%,
              93.43%,
              125.08%);
            @include position-combo(bl);
          }
        }
      }
      &-content {
        width: 100%;
        position: relative;
        padding: var(--space-3);
        background-color: var(--white);
        pointer-events: unset;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: var(--z-negative);
          transition: 500ms;
          border-radius: 0 0 0.2rem 0.2rem;
          background: linear-gradient(98.69deg,
              hsl(var(--hsl-secondary-light) / 1) 14.5%,
              hsl(var(--hsl-primary) / 1) 171.8%);
          pointer-events: none;
          @include position(0, null, null, 0);
        }
        .item-type-icon {
          color: var(--white);
          display: inline-block;
          @include position(-5rem, null, null, 1.5rem);
        }
      }
      &-title {
        @include truncate(2, 14, null, $font-primary);
      }
    }
  }
}
// how to use
// @include listing-card(rhs);
// @include listing-card(card);
// @include listing-card(overlay);
@mixin bgImg($image-location) {
  background-image: url("/static-assets/images/#{$image-location}?v=#{$image-version}");
}
// how to use
// @include bgImg("svg/logo.svg") // make sure to add double quotes
@mixin background($bg-Img: null, $bg-Props...) {
  @if $bg-Img ==null {
    background: $bg-Props;
  }
  @else {
    background: url('/static-assets/images/#{$bg-Img}?v=#{$image-version}') $bg-Props;
  }
}
// how to use
// @include bgProp("live-stream/detail.jpg", hsl(var(--color-hsl-primary)) fixed no-repeat top/cover);
// @include bgProp("live-stream/detail.jpg", fixed no-repeat top/cover);
// @include bgProp("live-stream/detail.jpg", fixed top/cover);
// @include bgProp("live-stream/detail.jpg", no-repeat top/cover);
// @include bgProp("live-stream/detail.jpg", no-repeat top);
// @include bgProp(null, hsl(var(--color-hsl-primary)) fixed no-repeat top/cover);
// @include bgProp("live-stream/detail.jpg", linear-gradient(lightgreen, lightblue) fixed no-repeat top/cover);
// @include bgProp("live-stream/detail.jpg", linear-gradient(90deg, hsl(var(--color-hsl-success-text)) 0.93%, hsl(var(--color-hsl-white-default)) 100%));
// @include bgProp("live-stream/detail.jpg", linear-gradient(90deg, hsl(var(--color-hsl-success-text)) 0.93%, hsl(var(--color-hsl-white-default)) 100%) repeat left/cover);
@mixin hamburger() {
  .btn-hamburger {
    position: relative;
    &:before {
      content: "";
      width: 0.1rem;
      height: 50%;
      left: -1rem;
      @extend %white-bg-5;
      @extend %position-y-center;
    }
    .line {
      fill: none;
      stroke: var(--white);
      stroke-width: 4;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
      &:first-child {
        stroke-dasharray: 60 207;
      }
      &:nth-child(2) {
        stroke-dasharray: 60 60;
      }
      &:nth-child(3) {
        stroke-dasharray: 60 207;
      }
    }
    &[aria-expanded="true"] {
      .line {
        &:nth-child(1) {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
        }
        &:nth-child(2) {
          stroke-dasharray: 1 60;
          stroke-dashoffset: -30;
        }
        &:nth-child(3) {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
        }
      }
    }
  }
}
@mixin custom-linear-gradient($color1: var(--secondary-light),
  $color2: hsl(var(--hsl-secondary-light) / 0.9),
  $stop1: 33.57%,
  $stop2: 76.42%,
  $stop3: 105.47%,
  $direction: 180deg) {
  background: linear-gradient($direction,
      transparent $stop1,
      $color1 $stop2,
      $color2 $stop3 );
}
// SCSS Mixin to create separators (horizontal or vertical) with optional override.
// Parameters:
//   $space: Space value for the separator. Default is 100%.
//   $type: Type of separator ('hori' or 'vert'). Default is 'hori'.
//   $thickness: Thickness of the separator. Default is .1rem.
//   $color: Color of the separator. Default is hsl(0, 0%, 100%, 0.2).
@mixin separator($space: 100%,
  $type: x,
  $thickness: 0.1rem,
  $colorVal: white,
  $opacity: 2) {
  $color: hsl(var(--hsl-#{$colorVal}) / #{$opacity * 0.1});
  // Set common styles for the separator
  // Horizontal separator
  @if $type ==y {
    // Default styles
    position: relative;
    &::after {
      content: "";
      width: $thickness;
      height: $space;
      background-color: $color;
      @include position-combo(y-center, 0);
    }
  }
  // Vertical separator
  @else if $type ==x {
    position: relative;
    &::after {
      content: "";
      width: $space;
      height: $thickness;
      background-color: $color;
      @include position-combo(x-center, null, 0);
    }
  }
}
// how to use
// @include separator(80%);
// @include separator(80%.x,.2rem,black,2);
// @include separator(length, direction, thickness , color, opacity);
@mixin breadcrumb-bg-full() {
  .article-head,
  .waf-head {
    isolation: isolate;
    position: relative;
    padding-inline: var(--space-3);
    margin-inline: var(--space-3-neg);
    &::before {
      content: "";
      height: calc(100% + var(--breadcrumb-height));
      z-index: var(--z-breadcrumb-neg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      @include bg(secondary-dark);
      @include bgImg("cssimages/breadcrumb-bg-mob.png");
      @include position(calc(var(--breadcrumb-height) * -1), null, null, 0);
    }
  }
}
@mixin breadcrumb-bg-half() {
  .waf-breadcrumb {
    position: relative;
    &::before {
      content: "";
      z-index: var(--z-breadcrumb-neg);
      background-size: cover;
      background-position: 0 12%;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      @include position(0, null, null, 0);
      @include bgImg("cssimages/breadcrumb-bg-mob.png");
    }
  }
}
@mixin backdropBlur($blur) {
  -webkit-backdrop-filter: blur($blur);
  backdrop-filter: blur($blur);
}
// how to use 
// @include backdropBlur(1rem)
@mixin mq($breakpoint) {
  $size: map-get($map: $media-query,
      $key: $breakpoint,
    );
  @media (min-width: $size) {
    @content;
  }
}
// how to use
// @include mq(col-md) // for media-query tablet
// @include mq(col-lg) // for media-query small screen laptop
// @include mq(col-xl) // for media-query medium screen laptop
// @include mq(col-xl) // for media-query large screen laptop
//This is for desktop for mobile we have extend with same name
@mixin commonCardWithMoreButton($card: card, $count: 4) {
  @include listing-card($card);
  .head-wrap {
    gap: var(--space-2);
    @include flex-config(flex, row, flex-start, center);
  }
  .head-tab {
    position: static;
    a {
      padding: var(--space-1) var(--space-2);
      border-radius: var(--half-radius);
      font-size: 0;
      gap: var(--space-0);
      transition: 300ms ease-in-out;
      @include bg(primary);
      &:hover {
        padding: var(--space-1) var(--space-3);
        gap: var(--space-1);
        font-size: 1.1rem;
      }
      &::after {
        width: unset;
        height: unset;
        border-radius: 0;
      }
    }
  }
  .article {
    &-list {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
      @include card-count($count, var(--space-3));
      &::after {
        content: unset;
      }
    }
  }
}
@mixin common-more-button($color: primary) {
  .waf-head {
    margin-bottom: var(--space-3);
    .title {
      margin-bottom: 0;
    }
  }
  .head-wrap {
    gap: var(--space-2);
    @include flex-config(flex, row, flex-start, center);
  }
  .head-tab {
    position: static;
    a {
      padding: var(--space-1) var(--space-2);
      border-radius: var(--half-radius);
      font-size: 0;
      gap: var(--space-0);
      transition: 300ms ease-in-out;
      @include bg($color);
      &:hover {
        padding: var(--space-1) var(--space-3);
        gap: var(--space-1);
        font-size: 1.1rem;
      }
      &::after {
        width: unset;
        height: unset;
        border-radius: 0;
      }
    }
  }
}
@mixin textstroke($stroke-color: white) {
  -webkit-text-stroke-width: .05rem;
  -webkit-text-stroke-color: var(--#{$stroke-color});
}
@mixin ribbon($ribbon-color: white, $direction) {
  background: var(--#{$ribbon-color});
  box-shadow: 0.1rem 0.1rem 1rem hsl(var(--hsl-black) / 0.3);
  #{$direction}: -1rem;
  @if $direction ==right {
    border-top-left-radius: var(--rounded-radius);
    border-bottom-left-radius: var(--rounded-radius);
    @extend %position-t-r;
  }
  @else {
    border-top-right-radius: var(--rounded-radius);
    border-bottom-right-radius: var(--rounded-radius);
    @extend %position-t-l;
  }
  @extend %px-2;
  @extend %py-1;
  &::before {
    content: "";
    @if $direction ==right {
      border-bottom: 1rem solid hsl(var(--hsl-shimmer-dark));
      border-right: 1rem solid transparent;
      @include position(-1rem, 0);
    }
    @else {
      border-bottom: 1rem solid hsl(var(--hsl-shimmer-dark));
      border-left: 1rem solid transparent;
      @include position(-1rem, null, null, 0);
    }
    @extend %w-0;
    @extend %h-0;
  }
}