// shimmer animation starts
@keyframes shimmerAnimation {
  100% {
    transform: translateX(100%);
  }
}
// shimmer animation starts
// marquee 
@keyframes marquee {
  100% {
    transform: translateX(-100%);
  }
}
// Animations for Gradient Overlay
@keyframes slideOverlayIn {
  0% {
    opacity: 1;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fading {
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotateRev {
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes gradient-bg {
  0% {
    background-position: 0% center;
  }
  50% {
    background-position: 100% center;
  }
  100% {
    background-position: 0% center;
  }
}
@keyframes zoombg {
  0% {
    left: -20%;
  }
  100% {
    left: -10%;
  }
}
@keyframes opennow {
  0% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateY(0.3rem);
  }
}
@keyframes opennowleft {
  0% {
    transform: translateX(-0.3rem);
  }
  100% {
    transform: translateX(0.3rem);
  }
}
// CONTEST PAGE CONFETTI
@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}
@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}
@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}