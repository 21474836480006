@use "../config/" as *;

.promo-anchor,
.footer-anchor,
.social-anchor,
.social-icon {
    &::before {
        font-family: $font-icon;
    }
}

@each $icon,
$value in $social {
    .icon-#{$icon},
    .promo-anchor-#{$icon},
    .footer-anchor-#{$icon},
    .social-link-#{$icon} .social-anchor {
        &::before {
            content: $value;
        }
    }
}

.article-list {
    .social {
        &-share {
            width: 3rem;
            height: 3rem;

            &.active {
                .social-share-wrap {
                    @extend %flex;
                }
            }

            .share {
                &-icon {
                    width: 3rem;
                    height: 3rem;
                    aspect-ratio: 1/1;
                }

                &-label {
                    @extend %d-none;
                }
            }

            .icon-b-share {
                &::before {
                    @include icon(share);
                }
            }

            .icon-b-close {
                &::before {
                    @include icon(close);
                    @extend %white;
                }
            }

            &-wrap {
                @extend %d-none;
                @extend %position-t-r;
            }
        }

        &-items {
            @extend %flex;
            @extend %black-bg;
        }

        &-icon {
            width: 3rem;
            height: 3rem;
            aspect-ratio: 1/1;

            &::before {
                width: 3rem;
                height: 3rem;
                font: 1.8rem $font-icon;
                color: var(--white);
                @extend %flex-c-c;
                @extend %white;
            }
        }

        &-wrap {
            @extend %flex;

            .close {
                width: 3rem;
                height: 3rem;
                @extend %black-bg;
            }
        }
    }
}