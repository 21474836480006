@use '../config/' as *;
.waf-poll {
    margin-top: 2rem;
    overflow: hidden;
    background: linear-gradient(133deg, hsl(var(--hsl-secondary) / 1) 0%, hsl(var(--hsl-secondary-light) / 1) 51.02%, hsl(var(--hsl-secondary-dark) / 1) 100%);
    @extend %p-0;
    @extend %m-0;
    @extend %relative;
    &::after {
        content: '';
        z-index: var(--z-negative);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        pointer-events: none;
        opacity: 0.2;
        transform: scale(1.3);
        @extend %position-t-l;
        @extend %w-100;
        @extend %h-100;
        @include bgImg("svg/hexagon.svg");
    }
    .poll-head {
        .title {
            @extend %white;
            @extend %font-20-pb;
        }
    }
    .radio-container {
        cursor: pointer;
        border: 0.1rem solid hsl(var(--hsl-white) / 0.2);
        @extend %half-radius;
        @extend %px-4;
        @extend %py-2;
        @extend %flex-fs-c;
        @extend %gap-3;
        @extend %mb-3;
        .text {
            height: fit-content;
            @extend %font-14-pm;
            @extend %white;
        }
    }
    .form-radio-input {
        position: absolute;
        pointer-events: none;
        opacity: 0;
        width: 0;
        height: 0;
        &:checked ~ .radiomark:after {
            @extend %primary-light;
        }
    }
    .radiomark {
        &::after {
            @extend %white;
            @include icon(point, 23);
        }
    }
    .btn-fill {
        @extend %w-100;
    }
    .poll-count {
        @extend %flex-sb-c;
        @extend %relative;
        @extend %pb-2;
        @extend %mb-3;
        .text {
            @extend %font-14-pm;
            @extend %white;
        }
        &.lead {
            .progress-count {
                @extend %primary-bg;
            }
        }
    }
    .progress {
        &-bar-holder,
        &-bar {
            height: 0.5rem;
            @extend %half-radius;
            @extend %primary-bg-5;
            @extend %position-b-l;
            @extend %w-100;
        }
        &-bar {
            display: inline-block;
            @extend %primary-bg;
        }
        &-count {
            @extend %font-12-pm;
            @extend %white;
            @extend %primary-light-bg;
            @extend %p-1;
            @extend %half-radius;
        }
    }
    .poll-body {
        border-top: 0.1rem solid hsl(var(--hsl-white) / 0.2);
        @extend %pt-3;
    }
    .waf-body {
        isolation: isolate;
        z-index: var(--z-poll-body);
        @extend %relative;
    }
}
@include mq(col-md) {
    .waf-poll {
        .radio-box-wrap {
            gap: var(--space-3);
            flex-wrap: wrap;
            @include flex-config(flex);
        }
        .radio-container {
            margin-bottom: 0;
        }
        .vote-now {
            margin-top: var(--space-3);
        }
        .radio-box {
            flex-basis: calc(50% - var(--space-3));
            width: calc(50% - var(--space-3));
        }
        &::after {
            width: 30%;
            min-width: 30rem;
            aspect-ratio: 1/1;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top left;
            transform: translate(-50%, -50%);
            @include bgImg("svg/hexagon.svg");
        }
        // .poll-result-wrap{
        //     @include grid(2,var(--space-3));
        // }
        .poll-count {
            margin-bottom: var(--space-2);
            &:last-child {
                margin-bottom: 0;
            }
            .text {
                font-size: 1.4rem;
            }
        }
    }
    .waf-row-poll-bg {
        .container {
            padding: 0;
        }
    }
}
@include mq(col-lg) {
    .waf-poll {
        margin: var(--space-0);
    }
    .waf-row-poll-bg .row {
        align-items: center;
    }
}
@include mq(col-xxl) {
    .waf-poll {
        .poll-count {
            margin-bottom: var(--space-5);
            &:last-child {
                margin-bottom: 0;
            }
            .text {
                font-size: 1.8rem;
            }
        }
    }
}
@media (min-width:$desktop-min-width) and (max-width:$desktop-max-width) {
    .waf-poll {
        .poll-head .title {
            font-size: 2rem;
        }
        .radio-container {
            padding-block: var(--space-1);
            .text {
                font-size: 1rem;
            }
        }
        .radiomark::after {
            font-size: 1.4rem;
        }
    }
}