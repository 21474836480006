@use "../config" as *;
html {
	@extend %w-100;
	&.no-scroll,
	&.no-scroll body {
		height: var(--window-inner-height);
		touch-action: none;
		-ms-scroll-chaining: none;
		overscroll-behavior: none;
		-webkit-overflow-scrolling: auto;
		@extend %hidden;
	}
	&[lang="bn"],
	&[lang="ml"] {
		.site {
			&-search {
				@extend %d-none;
			}
			&-login {
				margin-left: auto;
			}
		}
	}
}
.hide-filters {
	.more-filters {
		.waf-select-box {
			&:nth-last-child(2) {
				@extend %d-none;
			}
		}
	}
}
[v-cloak] {
	visibility: hidden;
}
.grecaptcha-badge {
	visibility: hidden;
}
.waf-component {
	padding: var(--space-4);
	margin-bottom: var(--space-4); // avoid magin collapse
}
.img-box {
	@extend %shimmer-light-bg;
	@extend %half-radius;
	@extend %hidden;
}
img {
	display: block;
	height: auto;
	image-rendering: -webkit-optimize-contrast;
	object-fit: cover;
	object-position: top center;
	@extend %w-100;
}
[class^="img-"] {
	@extend %relative;
	img {
		@extend %h-100;
		@extend %position-t-l;
		&[alt] {
			font-size: 1.2rem;
			@extend %black-7;
		}
	}
}
@each $width, $height in $aspect-ratio {
	.img-#{$width}by#{$height} .img-box {
		padding-bottom: calc(($height / $width) * 100%);
	}
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	@extend %m-0;
}
/* Firefox */
input[type="number"] {
	appearance: textfield;
	-moz-appearance: textfield;
}
.desktop {
	@extend %d-none;
}
.mobile {
	@extend %d-block;
}
@each $width in $flex-width {
	.m-w-#{$width} {
		width: calc($width * 1%);
		&-gap {
			width: calc((#{$width  * 1%} - var(--space-1)));
		}
	}
}
//project css start
/* web setup css done */
/* project common css start */
.main-wrap {
	padding-top: var(--header-height);
	min-height: var(--window-inner-height);
}
// max-width style
.layout-wrapper {
	width: 100%;
	max-width: var(--container-max-width);
	margin: auto;
}
// waf head common styles
.waf-head {
	.title {
		@extend %uppercase;
		@extend %font-22-pb;
	}
	.head-wrap {
		@extend %flex-sb-c;
	}
	.head-tab {
		li {
			// border: 0.1rem solid var(--accent);
			list-style: none;
			@extend %text-center;
			@extend %capitalize;
			@extend %half-radius;
		}
		a {
			@extend %p-1-3;
			@extend %d-block;
			@extend %font-12;
			@extend %white;
		}
	}
}
// buttons style starts
.btn-site,
.btn-fill {
	@extend %btn-fill;
}
.btn-outline {
	@extend %btn-outline;
}
.loadmore {
	padding: var(--space-2) var(--space-8);
	border: 0.1rem solid;
	@extend %rounded-radius;
	@extend %black;
}
.loadmore-wrap {
	margin: var(--space-4) auto;
	display: block;
	width: max-content;
}
.scroll-top {
	z-index: var(--z-scrolltop);
	position: fixed;
	bottom: var(--space-3);
	right: var(--space-3);
	@extend %d-none;
	.scroll-top-btn {
		width: 4rem;
		aspect-ratio: 1/1;
		@extend %relative;
		@extend %primary-bg;
		@extend %half-radius;
		@extend %flex-c-c;
		&::after {
			@include icon(chevron-up, 16);
			@extend %white;
			@extend %flex-c-c;
			@extend %position-center;
		}
	}
}
.waf-component.waf-loader {
	width: var(--window-inner-width);
	height: calc(var(--window-inner-height) - var(--header-height));
	z-index: var(--z-loader);
	@extend %m-0;
	@extend %white-bg;
	@include position(var(--header-height), null, null, 0, fixed);
	@include flex-config(none, null, center, center);
	.loader {
		width: 10rem;
		height: 10rem;
		background-size: 4.5rem;
		background-position: center center;
		background-repeat: no-repeat;
		@extend %p-3;
		@extend %relative;
		@extend %primary-bg;
		@extend %circle-radius;
		@extend %flex-c-c;
		@include bgImg("svg/isl-navbg.svg");
	}
}
.item-type-video {
	.item-type-icon {
		&::before {
			@include icon(play, 25, null);
		}
	}
}
.item-type-photo {
	.item-type-icon {
		&::before {
			@include icon(image, 20, null);
		}
	}
}
.item-type-article {
	.item-type-icon {
		&::before {
			@include icon(article, 25, null);
		}
	}
}
.waf-row-club-video,
.waf-row-club-photo,
.waf-row-home-video,
.waf-row-home-news,
.waf-row-home-photo {
	background-image: linear-gradient(97.02deg,
			hsl(var(--hsl-secondary) / 1) -1.04%,
			hsl(var(--hsl-secondary-light) / 1) 52.04%,
			hsl(var(--hsl-secondary-dark) / 1) 103%);
	.waf-head {
		.title {
			@extend %white;
		}
		.head-tab {
			a {
				@extend %white;
			}
		}
	}
	.waf-body {
		&::after {
			@extend %white-bg-2;
		}
	}
	.waf-listing {
		.article {
			&-content {
				@extend %white;
				@extend %transparent-bg;
				a {
					@extend %white;
				}
			}
			&-meta {
				.meta {
					@extend %white-8;
				}
			}
			&-list {
				&::after {
					@extend %white-bg-2;
				}
			}
		}
	}
}
.waf-row-home-news {
	.waf-listing {
		.second-list {
			.article-content {
				background-color: transparent;
			}
		}
	}
}
.waf-row-white-bg-list {
	@extend %white-bg;
	@include border(10, light-grey, 10, bottom);
}
.waf-row-grey-bg-list {
	@extend %light-grey-bg;
	@include border(10, white, 10, bottom);
}
.waf-component {
	&.waf-listing,
	&.waf-fixtures-page {
		@extend %pt-0;
	}
}
.seperator {
	border-style: solid;
	@extend %white-2;
}
.hidden {
	@extend %d-none;
}
/* home page */
.home {
	.body-wrap {
		@extend %flex-column;
	}
	.scroll-top {
		bottom: calc(var(--scorestrip-toggle-height) + var(--quicklink-head-height) + 4rem);
	}
}
/* home page poll end */
// .quicklink-section {
// 	@extend %d-none;
// }
.home {
	// .quicklink-section {
	// 	@extend %d-block;
	// }
	.breadcrumb-wrap {
		@extend %d-none;
	}
}
.webview {
	--header-height: 0rem;
	.secondary-header {
		@extend %d-none;
	}
	.main-wrap {
		padding-top: 0;
	}
	.header-wrap {
		display: none;
	}
	&.club-page {
		--header-height: 0rem;
	}
	.footer-wrap {
		display: none;
	}
	.breadcrumb-wrap {
		display: none;
	}
	.waf-games .waf-head {
		display: none;
	}
	.waf-player {
		.preview-swiper {
			display: none;
		}
		.card-info-wrapper {
			margin-top: var(--space-2);
		}
	}
	.waf-footballscorecard {
		.listing-section {
			@extend %d-none;
		}
	}
}
.waf-games {
	.waf-body {
		margin-inline: calc(var(--container-white-space) * -1);
	}
}
// buttons style starts
@include mq(col-md) {
	html {
		&[lang="bn"],
		&[lang="ml"] {
			.site {
				&-login {
					margin-left: 0;
				}
			}
		}
	}
	.mobile {
		display: none;
	}
	.desktop {
		display: block;
	}
	@each $width in $flex-width {
		.w-#{$width} {
			width: calc($width * 1%);
			&-gap {
				width: calc((#{$width * 1%} - var(--space-2)));
			}
		}
	}
	.item-type-video {
		.item-type-icon {
			&::before {
				font-size: 3.7rem;
			}
		}
	}
	.item-type-photo {
		.item-type-icon {
			&::before {
				font-size: 2.3rem;
			}
		}
	}
	.item-type-article {
		.item-type-icon {
			&::before {
				font-size: 2.3rem;
			}
		}
	}
	.waf-listing {
		.waf-head {
			.head-tab {
				a {
					color: var(--white);
				}
			}
		}
	}
	.waf-row-grey-bg-list {
		border: 0;
	}
	.waf-row-white-bg-list {
		border: 0;
		background-color: transparent;
	}
	// used for adding pattern on details page or any other page which will have fixed pattern
	.pattern-bg {
		&::before,
		&::after {
			content: "";
			width: 20rem;
			height: 23rem;
			position: fixed;
			opacity: 0.2;
			z-index: var(--z-bg-pattern);
			background-size: cover;
			pointer-events: none;
			@include bgImg("svg/hexagon.svg");
		}
		&::after {
			top: 30%;
			left: 0;
			transform: translateX(-50%);
		}
		&::before {
			top: 60%;
			right: 0;
			transform: translateX(50%);
		}
		.footer-wrap {
			z-index: var(--z-footer);
			position: relative;
		}
		.waf-component:where(:not(.waf-breadcrumb, .waf-gallery-page, .quicklink-section)) {
			position: relative;
			z-index: var(--z-component-pattern);
		}
	}
	.waf-head .head-tab li:hover {
		@include bg(black);
		a {
			font-weight: 700;
		}
	}
	.scorestrip-active {
		.waf-row-home-showcase {
			.dependancy-wrapper .accordion-body {
				height: calc(var(--window-inner-height) - var(--header-height) - var(--scorestrip-toggle-height));
				top: calc(var(--header-height) + var(--scorestrip-toggle-height));
			}
			.img-box,
			.preview-swiper {
				height: calc(var(--window-inner-height) - var(--header-height) - var(--scorestrip-toggle-height));
			}
		}
		.main-wrap {
			padding-top: calc(var(--header-height) + var(--scorestrip-toggle-height));
			min-height: calc(var(--window-inner-height) - var(--header-height) - var(--scorestrip-toggle-height));
		}
		.scroll-top {
			display: none;
		}
	}
	.scroll-top {
		bottom: 5rem;
		right: 5rem;
	}
}
@include mq(col-xxl) {
	.waf-poll {
		.poll-head {
			.title {
				font-size: 3.2rem;
				line-height: 1.5;
			}
		}
		.radio-container {
			padding-block: var(--space-3);
			.text {
				font-size: 1.6rem;
			}
		}
		.btn-fill {
			padding-block: var(--space-3);
			.btn-text {
				font-size: 1.6rem;
			}
		}
	}
}
.filter-hidden {
	.filter-section {
		display: none;
	}
}
.footer-hide {
	.footer-wrap {
		@extend %d-none;
	}
}