@use "../config" as *;
.waf-select-box {
    --z-dropdown: 5;
    @extend %relative;
    @extend %white-bg;
    @extend %py-4;
    @extend %px-3;
    @extend %half-radius;
    .dropdown-close {
        @extend %d-none;
    }
    &.active {
        .select-box-wrap {
           @extend %d-block;
        }
        .selected-title {
            .title{
                @extend %py-2;
                &::before{
                    content: "";
                }
            }
            &::after {
                transform: rotate(180deg);
            }
        }
    }
    .selected-title {
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @extend %relative;
        @extend %font-16-pb;
        @extend %w-100;
        @extend %h-100;
        @extend %flex-column-c-fs;
        .title {
            @extend %m-0;
            @extend %font-12-pb;
            @extend %w-100;
            @extend %relative;
            @extend %pt-2;
            &::before {
                content: unset;
                height: 0.1rem;
                @extend %w-100;
                @extend %secondary-dark-bg-1;
                @extend %position-b-l;
            }
        }
        .sub-title {
            @extend %font-12-pb;
        }
        &::after {
            transition: 300ms;
            @include position(var(--space-1), var(--space-2));
            @include icon(chevron-down, 12);
        }
    }
    .select-box-wrap {
        min-width: 27rem;
        box-shadow: -0.2rem 0.2rem 1rem -0.5rem hsl(var(--hsl-black) / 0.7);
        z-index: var(--z-dropdown);
        top: 100%;
        border-radius: 0 0 var(--half-radius) var(--half-radius);
        transition: 300ms;
        @extend %d-none;
        @extend %my-0;
        @extend %mx-auto;
        @extend %text-center;
        @extend %p-0;
        @extend %white-bg;
        @extend %position-t-l;
    }
    .select-list {
        overflow: auto;
        max-height: 30rem;
        overflow-x: hidden;
        @extend %pl-0;
        .list-item {
            cursor: pointer;
            @extend %font-12-pr;
            @extend %w-100;
            @extend %relative;
            @extend %half-radius;
            @extend %d-block;
            @extend %pt-2;
            @extend %text-left;
            &:not(:last-child) {
                @extend %py-2;
                &::before {
                    content: "";
                    height: 0.1rem;
                    @extend %w-100;
                    @extend %secondary-dark-bg-1;
                    @extend %position-b-l;
                }
            }
            &.active {
                @extend %my-3;
                @extend %primary-bg;
                @extend %p-1-4;
                @extend %white;
                &::before{
                    @extend %d-none;
                    // bottom: -1rem;
                    // width: 30rem;
                }
                &::after {
                    bottom: var(--space-3-neg);
                }
            }
            a {
                color: inherit;
                display: inline-block;
            }
        }
    }
}