@use '../config/' as *;
.filter-section {
    @include position(0, var(--space-3), null, null);
    .select-box-wrap {
        @extend %py-2;
    }
    .filter {
        &-wrap {
            @extend %d-none;
        }
    }
    .dropdown-close {
        @extend %d-none;
    }
    .btn-resetfilter {
        @extend %d-none;
    }
    .btn-more {
        @extend %font-12-pm;
        @extend %py-1;
        @extend %px-3;
        @extend %relative;
        @extend %gap-1;
        @extend %flex-c-c;
        @extend %accent-bg;
        @extend %quarter-radius;
        &::before {
            @include icon(filter, 16);
        }
        .btn-text {
            @extend %font-0;
            &::after {
                content: "Filter by";
                @extend %font-12;
            }
        }
    }
    .more-filters {
        z-index: var(--z-filter);
        height: calc(100% - var(--header-height));
        @include position(var(--header-height), 0, null, null, fixed);
        @extend %d-none;
        @extend %w-100;
        @extend %shimmer-light-bg;
        .waf-select-box {
            border-radius: 0;
            &:not(:last-child) {
                @extend %mb-3;
                @include border(1, black, 0.8, bottom);
            }
            .title {
                width: max-content;
                @extend %primary-bg;
                @extend %p-1-3;
                @extend %mt-1;
                @extend %secondary-dark;
                @extend %half-radius;
            }
            &.active {
                .title {
                    display: none;
                }
            }
        }
        .selected-title {
            .title {
                @extend %white;
            }
        }
        .select {
            &-box-wrap {
                position: unset;
                box-shadow: unset;
            }
            &-list {
                padding-left: unset;
                overflow: unset;
                max-height: unset;
            }
        }
        .head {
            height: 5rem;
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            @extend %flex-sb-c;
            @extend %accent-bg;
            @extend %pt-6;
            @extend %pb-4;
            @extend %px-3;
            .title {
                @extend %font-12-pb;
            }
        }
        .btn-cancel {
            @extend %ml-auto;
            @extend %flex-n-c;
            &::after {
                @include icon(close);
                @extend %pl-2;
            }
        }
        .body {
            height: calc(100% - 8rem - 5rem);
            @extend %y-auto;
        }
        .footer {
            height: 8rem;
            box-shadow: -0.4rem 0 0.4rem 0 hsl(var(--hsl-black) / 0.25);
            @include border(1, black, 2, top);
            @extend %flex-c-c;
            @extend %py-4;
            @extend %px-2;
            @extend %white-bg;
            @extend %gap-2;
            .btn-site {
                @extend %my-2;
            }
            .btn-applyfilter,
            .btn-resetfilter {
                min-width: 14rem;
                height: 4rem;
                width: 50%;
                font-weight: 700;
                @extend %flex-c-c;
                .btn-text {
                    @extend %uppercase;
                }
            }
            .btn-resetfilter {
                @extend %half-radius;
                @extend %shimmer-light-bg;
                @extend %secondary-light;
            }
            .btn-applyfilter {
                @extend %btn-fill;
                .btn-text {
                    @extend %white;
                }
            }
        }
    }
    &.active {
        .waf-select-box {}
        .more-filters {
            @extend %flex-column-n-n;
        }
    }
}
@include mq(col-md) {
    .filter-section {
        right: 0;
        .more-filters {
            box-shadow: 0.1rem 0.1rem 1rem hsl(var(--hsl-black) / 0.3);
            max-width: 35rem;
            .select-box-wrap {
                width: 100%;
            }
            .head {
                padding: var(--space-3);
            }
        }
    }
}