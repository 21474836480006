// this is kept just for color refence
$colors-hex-ref: (
	"primary":#ed3123,
	"primary-light": #f3574b,
	"secondary":#253b88,
	"secondary-light": #13296F,
	"secondary-medium": #060E59,
	"secondary-dark":#071544,
	"accent":#E2BE5F,
	"accent-light":#EBD191,
	"sky":#4C80E7,
	"sky-dark":#55ACEE,
	"neutral":#28293D,
	"dark-grey":#929398,
	"grey":#EBEBEB,
	"light-grey": #f7f7fa,
	"mid-grey": #B6B6B6,
	"medium-grey": #f4f4f4,
	"patch-gray":#ffffffb8,
	"black":#000,
	"white": #fff,
	"error": #FF3C3A,
	"success": #09C270,
	"warning": #FECD2D,
	"shimmer-light": #f1f1f1,
	"shimmer-dark":#ABABAB,
	"blac-drop": #000926,
	// club default style without id
	"club-primary":#253b88,
	"club-secondary":#ed3123,
	"club-varient":#f3574b,
	"club-accent":#E2BE5F,
	"club-list-gredient":#000,
	"light-silver": #d9d9d9,
	"accent-100": #D3A21F,
	"accent-200": #B58710,
	"accent-300": #7B5B05,
	"accent-400": #674B03
);
// add colours here in hsl format according to project
$colors: (
	"primary": (4, 85%, 53%),
	"primary-light": (4, 88%, 62%),
	"secondary": (227, 57%, 34%),
	"secondary-light": (226, 71%, 25%),
	"secondary-medium": (234, 87%, 19%),
	"secondary-dark": (226, 81%, 15%),
	"accent": (42, 69%, 63%),
	"accent-light": (43, 69%, 75%),
	"sky":(220, 76%, 60%),
	"sky-dark":(206, 82%, 63%),
	"neutral": (237, 21%, 20%),
	"dark-grey": (230, 3%, 58%),
	"grey": (0, 0%, 92%),
	"light-grey": (240, 23%, 97%),
	"mid-grey": (0, 0%, 71%),
	"medium-grey": (0, 0%, 96%),
	"patch-gray":(0, 0%, 100%),
	"black": (0, 0%, 0%),
	"white": (0, 0%, 100%),
	"error": (0, 100%, 60%),
	"success": (120, 77%, 41%),
	"warning": (46, 99%, 59%),
	"shimmer-light": (0, 0%, 95%),
	"shimmer-dark": (0, 0%, 67%),
	"back-drop" : (226, 100%, 7%),
	// club default style without id
	"club-primary":(227, 57%, 34%),
	"club-secondary":(4, 85%, 53%),
	"club-varient":(4, 87%, 62%),
	"club-accent":(44, 69%, 63%),
	"club-list-gredient":(0, 0%, 0%),
	"light-silver": (0, 0%, 85%),
	"accent-100": (44, 74%, 47%),
	"accent-200": (43, 84%, 39%),
	"accent-300": (44, 92%, 25%),
	"accent-400": (43, 94%, 21%)
);
$aspect-ratio: (
	4 3,
	3 4,
	16 9,
	1 1,
	2 3,
	9 16,
	21 9,
	18 9,
	18 6,
	6 4
);
$flex-width: (
	10,
	20,
	25,
	30,
	33,
	40,
	50,
	60,
	66,
	70,
	80,
	90,
	100
);
$font-detail: (
	inter:(pr:(Inter-Regular: 400),
		pm:(Inter-Medium: 500),
		pb:(Inter-Bold: 700)),
);
$directions: (
	t: -top,
	r: -right,
	b: -bottom,
	l: -left,
	x: -inline,
	y: -block,
);
$flex-spacing: (
	-sa: space-around,
	-sb: space-between,
	-se: space-evenly,
	-fe: flex-end,
	-fs: flex-start,
	-c:center,
	-bl:baseline,
	-s:stretch,
	-u:unset,
	-n:null
);
$media-query: (
	col: 300px,
	col-sm: 576px,
	col-md: 768px,
	col-lg:992px,
	col-xl: 1200px,
	col-xxl: 1600px,
);
$icons: (
	arrow-left: '\e800',
	arrow-right: '\e801',
	arrow-up: '\e802',
	arrow-down: '\e803',
	chevron-left: '\e804',
	chevron-right: '\e805',
	chevron-up: '\e806',
	chevron-down: '\e807',
	facebook: '\e808',
	filter: '\e809',
	link: '\e80a',
	play-store: '\e80b',
	app-store: '\e80c',
	tiktok: '\e80d',
	whatsapp-o: '\e80e',
	twitter: '\e83b',
	instagram: '\e810',
	linkedin: '\e811',
	pen-1: '\e812',
	close: '\e813',
	call: '\e814',
	play: '\e815',
	home: '\e816',
	info: '\e817',
	user: '\e818',
	flag: '\e819',
	search: '\e81a',
	rank: '\e81b',
	eye: '\e81c',
	eye-close: '\e81d',
	trend: '\e81e',
	share-o: '\e81f',
	share: '\e820',
	facebook-circle: '\e821',
	whatsapp: '\e822',
	article: '\e823',
	calander: '\e824',
	double-chevron-down: '\e825',
	double-chevron-left: '\e826',
	double-chevron-right: '\e827',
	double-chevron-up: '\e828',
	football: '\e829',
	image: '\e82a',
	internet: '\e82b',
	point: '\e82c',
	card: '\e82d',
	email: '\e82e',
	setting: '\e82f',
	pen: '\e830',
	delete: '\e831',
	logout: '\e832',
	android: '\e833',
	checkmark: '\e834',
	substitution: '\e839',
	whistel: '\e83a',
	x: '\e83b',
	reset: '\e83c',
	list: '\e83d',
	long-down-arrow: '\e83e',
	long-up-arrow: '\e83f',
	mic: '\e840',
	stadium-1: '\e841',
	camera: '\e85c',
	clock: '\e85d',
	stadium: '\e868',
	ticket: '\e86a',
	file: '\e86b',
	plus: '\e87c',
	checkmark-circle: '\f06d',
	youtube: '\f16a',
	fantasy:'\e842',
	preview:'\e843',
	add-calander:'\e844',
	pdf:'\e845',
	remove-profile:'\e846',
	edit-profile:'\e847',
	add-icon:"\e87b"
);
$social: (
	"facebook": "\e808",
	"youtube": "\f16a",
	"tiktok": "\e80d",
	"whatsapp": "\e80e",
	"twitter": "\e83b",
	"instagram": "\e810",
	"linkedin": "\e811",
	"copylink": "\e80a",
	"android": "\e80b",
	"ios": "\e80c",
);
$social-colors: (
	"facebook": 220 46% 48%,
	"youtube": 4 85% 53%,
	"instagram": 276 51% 47%,
	"twitter": 203 89% 53%,
);
$zindex: (
	site:-1,
	negative:-1,
	listing: 1,
	showcase-content:1,
	quick-menu: 3,
	club-logo: 1,
	country:1,
	reaction: 2,
	header-icon:3,
	header : 17,
	lang:3,
	over-menu:4,
	hamburger:5,
	dropdown:5,
	secondary-header: 4,
	breadcrumb: 3,
	breadcrumb-neg: -1,
	step:1,
	modal:30,
	login-bg:1,
	poll-body: 1,
	select:2,
	subul:2,
	home-standing: 5,
	home-showcase: -1,
	bg-pattern:1,
	bg-pattern-neg:-2,
	footer:2,
	component-pattern:2,
	profile-player:2,
	loader: 2,
	strip: 2,
	open-strip: 4,
	strip-modal: 3,
	fixtures-page-btn: 1,
	sticky-match:9,
	sticky-tab:7,
	timeline:15,
	tooltip:5,
	timeline-symbol: 6,
	event-common-symbol: 9,
	timeline-tooltip: 10,
	timeline-progressbar: 20,
	squad-pattern:1,
	players-modal:20,
	filter:4,
	writeup:18,
	masthead: 2,
	scrolltop:4,
	scorestrip-active:3,
	listing-head:2,
	card-info:2,
	gradient-live:1,
	takeover-content:2,
	sticky-team:10,
	masthead-logo:2,
	stats-nav-link: 2,
	home-stats: 1,
	home-stats-bg-before: -1,
	home-stats-bg-after: -2,
	info-section:2,
	home-btn:1,
	search-head:10,
	sticky-head:2,
	sync-cal:2,
	default: 1,
	nivia-plus-hover: 2
);
$club-colors: (
	// FC GOA
	"496": ("primary": (234, 87%, 19%), //#060E59
		"secondary": (24, 100%, 50%), //#FF6600
		"varient": (36, 100%, 69%), //#FFBF60
		"list-gredient": (234, 82%, 31%) //#0E1B90
	),
	// Kerala
	"498": ("primary": (54, 89%, 54%), //#F2DD21
		"secondary": (217, 84%, 52%), //#1E6DEB
		"varient": (54, 100%, 83%), //#FFF6A6
		"list-gredient": (217, 84%, 52%) //#1E6DEB
	),
	// M Bagan Super Giant
	"1874": ("primary": (150, 98%, 18%), //#01592D
		"secondary": (357, 59%, 42%), //#A92B31
		"varient": (150, 88%, 29%), //#098D4B
		"list-gredient": (150, 98%, 18%) //#01592D
	),
	// Northeast United FC
	"504": ("primary": (359, 76%, 51%), //#E12529
		"secondary": (0, 5%, 13%), //#221F1F
		"varient": (359, 100%, 80%), //#FF989A
		"list-gredient": (0, 5%, 13%) //#221F1F
	),
	// CHENNAI
	"505": ("primary": (42, 98%, 53%), //#FDB813 
		"secondary": (218, 68%, 34%), //#1C4792
		"varient": (42, 100%, 86%), //#FFEAB7
		"list-gredient": (227, 70%, 21%) //#10205B
	),
	// 506 Mumbai FC
	"506": ("primary":(204, 81%, 70%), //#73BEF0
		"secondary": (232, 48%, 26%), //#222A61
		"varient": (204, 100%, 89%), //#C9E9FF
		"list-gredient": (232, 48%, 26%) //#222A61
	),
	// Bengaluru
	"656": ("primary": (357, 85%, 53%), //#ED212A
		"secondary": (225, 59%, 39%), //#29479F
		"varient": (357, 100%, 80%), //#FF9BA0
		"list-gredient": (225, 59%, 39%) //#29479F
	),
	// East Bengal
	"1102": ("primary": (44, 100%, 49%), //#FCB900 
		"secondary": (356, 89%, 55%), //#F32735
		"varient": (42, 100%, 86%), //#FFEAB7
		"list-gredient": (356, 89%, 25%) //#7B070F
	),
	// Jamshedpur
	"1159": ("primary":(354, 85%, 52%), //#ED1B2F
		"secondary": (209, 100%, 28%), //#004A8F
		"varient": (354, 100%, 84%), //#FFABB3
		"list-gredient": (209, 100%, 28%) //#004A8F
	),
	// Punjab
	"1252": ("primary": (14, 88%, 55%), //#F15627
		"secondary": (38, 96%, 54%), //#FAA61A
		"varient": (6, 57%, 39%), //#9B362B
		"list-gredient": (6, 57%, 39%) //#9B362B
	),
	// Odisha FC
	"1499": ("primary": (0, 97%, 57%), //#FC2727
		"secondary": (247, 48%, 14%), //#171336
		"varient": (0, 94%, 81%), //#FC9F9F
		"list-gredient": (247, 48%, 14%) //#171336
	),
	// Hyderabad
	"1536": ("primary":(51, 100%, 50%), //#FFDA00
		"secondary": (220, 14%, 12%), //#1B1E24
		"varient": (51, 100%, 84%), //#FFF3AF
		"list-gredient": (220, 14%, 12%) //#1B1E24
	),
	// Mohammedan
	"1109": ("primary": (0, 0%, 0%), //#000000
		"secondary":(39, 79%, 55%), //#e7a933
		"varient": (40, 63%, 14%), //#3A2B0D
		"list-gredient": (0, 0%, 0%), //#000000
	),
);
$stats-item-list: (
	"position": "position",
	"games-played": "games-played",
	"goal-diff": "goal-diff"
);
$playScaniro: (
	video:play,
	fulltime: clock,
	goal:football,
	image:image,
	instagram:instagram,
	facebook:facebook,
	twitter:twitter,
	play:play,
	substitution:substitution,
	foul:whistel,
	commentary:mic,
	red-card:card,
	yellow-card:card,
	external:link,
	article:link,
	youtube:youtube,
	"missed-penalty": football,
	// "second-yellow":card,
	// substitution:info,
	// foul:info,
	// commentary:info
);
//Clip Path Animation for Circle
$clip-paths-circle: (
	right: ((0% at 0 100%), (141.4% at 0 100%)),
	left: ((0% at 100% 100%), (141.5% at 100% 100%)),
	center: ((0% at 50% 50%), (100.0% at 50% 50%))
);
$venueID: (
	496,
	498,
	504,
	505,
	506,
	568,
	656,
	1052,
	1102,
	1109,
	1159,
	1252,
	1499,
	1536,
	1874
);