@use '../config/' as *;
@forward './common';
// styles for user-profile
.load-bg-pattern {
    // html {
    .masthead-wrap {
        @include bgImg('profile/bg-m.png');
    }
    .waf-profile {
        @include bgImg("profile/profile-bg-m.png");
    }
    .profile-jersey .user-jersey::after {
        @include bgImg('profile/circle-ring.svg');
    }
}
// style for no fav team and no fav player
.no-datawrap {
    @extend %mt-4;
    @extend %px-4;
    @extend %py-2;
    @extend %white-bg-1;
    @extend %half-radius;
    @extend %font-14-pb;
}
// masthead section
.waf-profile {
    --jersey-space: 40rem;
    background-size: cover;
    @extend %pt-10;
    @extend %px-3;
    @extend %secondary;
    .layout-wrapper {
        max-width: 97rem;
        width: calc(100% - 4rem)
    }
    .form-gender {
        .selected-title {
            height: unset;
            opacity: 0.6;
            pointer-events: none;
            @extend %pl-0;
            @extend %transparent-bg;
            .btn-text {
                @extend %font-16-pb;
                @extend %uppercase;
            }
            &:after {
                display: none;
            }
        }
    }
    .favourite-player {
        .control-label {
            @extend %uppercase;
        }
    }
}
.masthead-wrap {
    background-size: cover;
    @extend %p-2-4;
    @extend %white-bg-3;
    @extend %quarter-radius;
    .otp-verify-section {
        @extend %flex-wrap;
        @extend %d-none;
        .form-group {
            border-bottom: 0;
        }
        &.otp-enabled {
            @extend %flex-sb-c;
        }
    }
    .show-avatar {
        pointer-events: none;
        @extend %relative;
        @extend %w-100;
        @extend %h-100;
        &[aria-expanded=true] ~ .select-list {
            @include dropdown(open);
        }
        &::after {
            opacity: 0;
            width: 3rem;
            height: 3rem;
            position: absolute;
            top: 15%;
            right: -5%;
            @include icon(pen, 12);
            @extend %white;
            @extend %flex-c-c;
            @extend %circle-radius;
            @extend %primary-bg;
        }
    }
    .avatar {
        transform: scale(1.1);
        @extend %flex-c-c;
        @extend %w-100;
        @extend %h-100;
        @extend %circle-radius;
        &-section {
            width: 13rem;
            height: 13rem;
            aspect-ratio: 1/1;
            margin-top: -6.5rem;
            @include border(4, white);
            @extend %relative;
            @extend %circle-radius;
            @extend %white-bg;
            .select-list {
                top: 5rem;
                transform: translateX(10rem);
                width: 19rem;
                padding-block: var(--space-1);
                .btn-text {
                    @extend %font-12;
                }
            }
            .active:before {
                font-size: 1rem;
                width: 1.5rem;
                height: 1.5rem;
            }
            .list-item:not(:last-child)::after {
                display: none;
            }
            .modal-body-wrap {
                max-width: 80rem;
                margin-top: calc((var(--header-height)/ 2) * -1);
            }
        }
        &-list {
            @include card-count(4, var(--space-3));
            @extend %flex-wrap;
            @extend %flex;
        }
        &-item {
            aspect-ratio: 1/1;
        }
        &-input {
            @extend %d-none;
            &:checked ~ .avatar-img {
                @include border(3, primary);
                @extend %accent-bg;
            }
        }
        &-img {
            aspect-ratio: 1/1;
            @include border(1, secondary, 2);
            @extend %transition;
            @extend %circle-radius;
            @extend %w-100;
            @extend %primary-bg-1;
        }
    }
    .change-profile .btn-icon {
        &::before {
            @include icon(edit-profile, 16)
        }
    }
    .remove-profile .btn-icon {
        &::before {
            @include icon(remove-profile, 16)
        }
    }
    .user-info {
        @extend %mt-4;
        .name {
            @extend %font-24;
            @extend %accent;
            @extend %uppercase;
        }
        .first-name {
            font-weight: 400;
        }
        .control-label {
            @extend %white-8;
            @extend %m-0;
            @extend %uppercase;
        }
    }
    .form {
        &-mail {
            .form-control {
                height: unset;
                border: 0;
                @extend %font-16-pb;
                @extend %pl-0;
                @extend %uppercase;
                @extend %transparent-bg;
                &:disabled {
                    opacity: 0.6;
                }
            }
            .btn-verify {
                top: var(--space-3);
            }
            .check {
                top: 1.8rem;
            }
        }
        &-otp {
            padding-bottom: var(--space-4)
        }
    }
    .otp {
        &-wrap {
            @extend %mt-4;
        }
        &-verify-section {
            @extend %mb-4;
        }
    }
    .radio-box-wrap {
        order: -1;
        @extend %d-none;
    }
}
.profile {
    &-setting {
        @extend %mb-4;
        @extend %ml-auto;
        @extend %flex-fe-fs;
        @extend %relative;
        .btn-form-submit {
            border-radius: .3rem;
            @extend %p-1-3;
            @extend %font-10-pb;
            @extend %capitalize;
        }
        .sub-ul {
            top: 100%;
            list-style: none;
            z-index: var(--z-subul);
            @include backdropBlur(1rem);
            @extend %d-none;
            @extend %quarter-radius;
            @extend %position-t-r;
            @extend %white-bg-8;
            @extend %p-1-3;
            .btn-text {
                @extend %font-10;
                @extend %capitalize;
            }
        }
        .list-item {
            @extend %py-1;
            @extend %my-1;
            &:not(:last-child) {
                &::after {
                    @extend %d-none;
                }
            }
        }
    }
    &-form {
        @extend %flex-n-n;
        @extend %gap-2;
        @extend %flex-wrap;
        .form-group {
            @extend %pb-2;
            @include border(1, white, 1, bottom);
        }
        .form-edit {
            border: 0;
            padding-top: .2rem;
            @extend %transparent-bg;
            @extend %font-16-pb;
            @extend %white;
            @extend %uppercase;
            &:disabled {
                opacity: 0.6;
                pointer-events: none;
            }
        }
    }
    &-jersey {
        @extend %my-2-neg;
        @extend %mx-4-neg;
        @extend %flex-fe-fe;
        @extend %hidden;
        .jersey {
            &-img {
                @extend %p-0;
                @extend %w-100;
                @extend %h-100;
                &-wrap {
                    padding: var(--space-10) 9rem 0 var(--space-4);
                }
            }
            &-view {
                @extend %w-100;
                @extend %h-100;
                @extend %circle-radius;
            }
            &-wrapper {
                margin-left: 27%;
                width: 10rem;
            }
            &-number {
                font-size: 4.6rem;
            }
            &-name {
                line-height: 2rem;
                font-size: 90%;
                width: 100%;
                max-width: 12rem;
                text-overflow: ellipsis;
                @extend %hidden;
                @extend %mt-1;
                @extend %mb-0;
            }
        }
        .user-jersey {
            width: var(--jersey-space);
            height: var(--jersey-space);
            aspect-ratio: 1/1;
            padding: 3%;
            isolation: isolate;
            transform: translate(25%, 21%);
            flex-shrink: 0;
            @extend %relative;
            &::after {
                content: "";
                position: absolute;
                inset: 0;
                z-index: var(--z-negative);
                animation: 10s rotate 1s infinite linear;
                transform: rotate(0);
                background-size: cover;
            }
        }
    }
}
.edit-icon {
    position: relative;
    top: -0.3rem;
    @extend %d-none;
    @extend %font-0;
    &::after {
        @include icon(pen, 12, 14);
        @extend %ml-1;
    }
}
.btn {
    &-form-submit {
        @extend %d-none;
    }
    &-edit-profile {
        @extend %flex-n-c;
        &::before {
            @include icon(pen, 10);
            @extend %mr-1;
        }
    }
    &-logout {
        @extend %flex-n-c;
        &::before {
            @include icon(logout, 10);
            @extend %mr-1;
        }
    }
    &-delete {
        @extend %flex-n-c;
        &::before {
            @include icon(delete, 10);
            @extend %mr-1;
        }
    }
    &-setting {
        @extend %flex-n-c;
        @extend %white;
        @extend %py-3;
        .btn-text {
            @extend %font-12;
            @extend %capitalize
        }
        &::before {
            @include icon(setting, 12);
            @extend %mr-1;
        }
        &[aria-expanded=true] ~ .sub-ul {
            @extend %d-block;
        }
    }
}
// fav club section 
.fav-club {
    &-section {
        @extend %d-none;
        @extend %my-6;
        .head-wrap {
            @extend %flex-n-c;
            @extend %gap-1;
            @extend %mb-4;
        }
        .title {
            @extend %mb-0;
            @extend %uppercase;
        }
    }
    &-list {
        @include grid(3, var(--space-3))
    }
    &-item {
        isolation: isolate;
        @include border(1, white, 4);
        @include backdropBlur(1rem);
        @include bg(white, 0.5);
        @extend %p-3;
        @extend %text-center;
        @extend %relative;
        @extend %quarter-radius;
        input[type="radio"]:checked {
            & ~ .radiomark {
                @extend %white-bg;
                &::after {
                    background: transparent;
                    font-size: 1.4rem;
                    line-height: 0.5;
                    @include icon(checkmark);
                    @extend %flex-c-c;
                    @extend %w-100;
                    @extend %h-100;
                    @extend %primary;
                }
            }
            & ~ .club-logo {
                &::after {
                    opacity: 1;
                }
            }
            & ~ .fav-club-name {
                .name {
                    font-weight: 700;
                }
            }
        }
        .name {
            line-height: 1.2;
            @extend %font-14;
            @extend %d-block;
        }
        .club {
            &-img {
                width: 6rem;
                height: 6rem;
                object-fit: contain;
                @extend %m-auto;
            }
            &-logo {
                &::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    opacity: 0;
                    background: linear-gradient(var(--club-varient), var(--club-primary));
                    z-index: var(--z-negative);
                    @extend %transition;
                }
            }
        }
        .radiomark {
            width: 2.3rem;
            height: 2.3rem;
            transform: translate(50%, -50%);
            border: 0;
            @extend %position-t-r;
        }
        .radio-container {
            flex-direction: column;
            justify-content: center;
            @extend %w-100;
            @extend %h-100;
            &::after {
                content: "";
                z-index: -1;
                @extend %quarter-radius;
                @extend %position-t-r;
                @extend %w-100;
                @extend %h-100;
            }
        }
    }
    &-stats {
        @extend %my-6;
        .title {
            @extend %mb-4;
            @extend %uppercase;
            @extend %font-14;
        }
    }
}
.stats {
    &-list {
        @include grid(4, var(--space-1));
    }
    &-item {
        @include bg(white, 0.5);
        @extend %flex-c-c;
        @extend %relative;
        @extend %text-center;
        .fav-club-item {
            @extend %w-100;
            &::after {
                content: "";
                background-color: transparent;
                background-size: cover;
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
                width: 1.7rem;
                height: 1.9rem;
                @include bgImg('profile/star.svg');
            }
            &.active {
                background: linear-gradient(var(--club-varient), var(--club-primary));
                @include border(1, white);
            }
        }
        &-fav {
            grid-row-end: span 2;
            @extend %w-100;
        }
        &-wdl {
            grid-column-end: span 3;
            @extend %flex;
            .stats-number {
                @extend %pl-0;
            }
        }
    }
    &-data {
        flex: 1;
        &:not(:last-child) {
            @include separator(70%, y)
        }
    }
    &-img {
        width: 2rem;
        height: 2rem;
    }
    &-number {
        @extend %pl-6;
        @extend %mb-1;
        @extend %accent;
        @extend %font-16-pb;
    }
    &-text {
        @extend %font-8-pr;
        @extend %uppercase;
    }
    &-image {
        position: absolute;
        top: 20%;
        left: 15%;
    }
}
// club follow
.club-follow {
    @extend %my-6;
    .head-wrap {
        @extend %flex-n-c;
        @extend %gap-1;
    }
    .title {
        @extend %uppercase;
        @extend %mb-0;
        &-1 {
            @extend %d-none;
        }
    }
    .club {
        &-list {
            overflow: auto;
            @include bg(white, 0.5);
            @include custom-scroll;
            @extend %flex;
            @extend %p-3;
            @extend %gap-3;
        }
        &-group {
            .club-logo {
                display: block;
            }
        }
        &-logo {
            width: 5.5rem;
            height: 5.5rem;
            @extend %circle-radius;
            @extend %hidden;
            @extend %white-bg;
        }
        &-item {
            border: 0;
            @extend %transparent-bg;
            @extend %p-0;
            @extend %relative;
            .checkmark {
                background-color: transparent;
                position: absolute;
                top: 0;
                right: 0;
                border: 0;
                box-shadow: unset;
                @extend %transparent-bg;
                @extend %d-none;
            }
        }
        &-img {
            @extend %p-1;
        }
        &-text {
            @extend %d-none;
        }
    }
}
.confirm-changes {
    @extend %d-none;
}
// favourite player 
.favourite-player {
    &-wrap {
        max-width: 100%;
        margin: auto;
        @include border(2, accent, 10, bottom);
        @extend %flex-c-fe;
    }
    .player {
        &-stats-list {
            z-index: var(--z-profile-player);
            @extend %flex-column;
            @extend %gap-2;
            @extend %relative;
            @extend %mb-7;
        }
        &-image {
            max-width: 30rem;
            margin-left: -10rem;
            isolation: isolate;
            @extend %relative;
            &::after {
                content: "";
                position: absolute;
                inset: 0;
                z-index: var(--z-negative);
                background: radial-gradient(circle, hsl(var(--hsl-sky)/0.5) 1%, transparent 65%);
                filter: blur(1rem);
            }
        }
        &-name {
            @extend %my-2;
            .name {
                display: inline;
                @extend %accent;
                @extend %font-22-pb;
            }
            .first-name {
                font-weight: 400;
            }
        }
        &-info-list {
            @extend %flex-n-c;
            @extend %gap-6;
            @extend %mb-4;
        }
        &-role {
            @include separator(100%, y);
            @extend %flex-n-c;
            @extend %gap-2;
            &::after {
                right: -1.5rem;
            }
            .role {
                @extend %white-bg-1;
                @extend %px-2;
                @extend %uppercase;
            }
        }
        &-country {
            @extend %flex-n-c;
            @extend %gap-2;
            .country-flag {
                width: 3rem;
                height: 2rem;
                object-fit: contain;
                aspect-ratio: unset;
                border-radius: unset;
            }
            .country-name {
                @extend %uppercase;
            }
        }
        &-content {
            .btn-fill {
                display: inline-block;
                @extend %py-2;
            }
        }
    }
    .stats {
        &-number {
            width: 8.8rem;
            flex-shrink: 0;
            @extend %text-center;
            @extend %font-30;
        }
        &-list {
            @extend %mb-2;
        }
        &-item {
            width: 16.8rem;
            height: 5.1rem;
            box-shadow: 0 0 .02rem .02rem hsl(var(--hsl-black)/0.25);
            @include backdropBlur(1rem);
            @extend %p-1;
        }
        &-data {
            @extend %flex-n-c;
            @extend %gap-1;
            @extend %text-left;
        }
        &-img {
            width: 3rem;
            height: 3rem;
        }
        &-text {
            @extend %font-10;
        }
    }
    &-info {
        max-width: 35rem;
        @extend %relative;
        @extend %py-4;
        @extend %m-auto;
        .control-label {
            @extend %white;
            @extend %w-100;
            @extend %font-14-pb;
            @extend %d-block;
        }
        .btn {
            &-edit {
                @extend %d-none;
                @extend %text-left;
                @extend %pb-2;
                .btn-text {
                    @extend %white;
                    @extend %w-100;
                    @extend %font-14-pb;
                    @extend %d-block;
                }
                &[aria-expanded=true] ~ .form-dropdown {
                    @include dropdown(open);
                }
            }
            &-list {
                @extend %flex-n-c;
                @extend %gap-2;
                @extend %w-100;
            }
        }
        .player {
            &-content {
                max-width: 21rem;
                @include border(1, white, 3, top);
                @extend %m-auto;
            }
            &-img {
                @extend %white-bg;
                @extend %hidden;
                @extend %circle-radius;
                &-wrap {
                    width: 4rem;
                    height: 4rem;
                    padding: .3rem;
                    @include border(3, secondary);
                    @extend %hidden;
                    @extend %circle-radius;
                }
            }
        }
        .selected-text {
            @extend %primary;
            @extend %mb-2;
            @extend %font-12-pb;
            @extend %uppercase;
        }
        .form {
            &-element {
                flex-direction: column;
                max-width: 23rem;
                @extend %m-auto;
            }
            &-dropdown {
                max-height: 30rem;
                overflow: auto;
                top: 4rem;
                z-index: var(--z-select);
                @include dropdown(close);
                @include backdropBlur(1rem);
                @extend %w-100;
                @extend %position-t-l;
                @extend %white-bg-8;
                @extend %p-3;
                .list-item {
                    @extend %py-1;
                    @extend %uppercase;
                    &:not(:last-child)::after {
                        @extend %d-none;
                    }
                }
                .btn-text {
                    @extend %uppercase;
                    @extend %font-14-pb;
                }
            }
        }
        .sub-ul {
            @extend %pl-0;
        }
    }
}
// changes on class edit-profile
.edit-profile {
    .btn-edit-profile {
        @extend %d-block;
    }
    .btn-setting {
        @extend %d-none;
    }
    .btn-form-submit {
        @extend %d-block;
    }
    .edit-icon {
        display: inline-block;
        @extend %white;
    }
    .fav-club-section {
        @extend %d-block;
    }
    .fav-club-stats {
        @extend %d-none;
    }
    .club-group {
        .club-logo {
            @extend %d-none;
        }
    }
    .club-follow {
        @extend %d-block;
        .title {
            &.title-1 {
                @extend %d-block;
            }
            &.title-2 {
                @extend %d-none;
            }
        }
        .club-item {
            .checkmark {
                @extend %flex;
            }
            input[type="checkbox"]:checked {
                & ~ .club-logo {
                    @include border(2, primary);
                }
            }
        }
    }
    .show-avatar {
        pointer-events: initial;
        &:after {
            opacity: 1;
        }
    }
    .favourite-player-info {
        .control-label {
            @extend %d-none;
        }
        .btn-edit {
            @extend %flex-c-c;
            @extend %uppercase;
        }
        .player-content .btn-fill {
            @extend %d-none;
        }
    }
    .confirm-changes {
        @extend %d-block;
    }
    .radio-box-wrap {
        @extend %flex;
    }
    .form-gender {
        .selected-title {
            pointer-events: unset;
            &::after {
                display: block;
            }
        }
    }
}
// colors for team according to team id
@include mq(col-md) {
    // styles for user-profile
    .load-bg-pattern {
        // html {
        .masthead-wrap {
            @include bgImg('profile/bg-d.png');
        }
        .waf-profile {
            @include bgImg('profile/bg.svg');
        }
    }
    // profile page
    .waf-profile {
        --jersey-space: 50rem;
        height: auto;
        min-height: calc(var(--window-inner-height) - var(--header-height));
        background-size: cover;
    }
    // masthead section
    .masthead-wrap {
        position: relative;
        display: flex;
        .form-mail,
        .otp-verify-section,
        .form-dob {
            order: -1;
        }
        .title {
            font-size: 1.4rem;
        }
        .avatar-list {
            @include card-count(6, var(--space-4));
        }
    }
    .profile {
        &-setting {
            margin-bottom: var(--space-1);
        }
        &-jersey {
            width: 40rem;
            height: 40rem;
            flex-shrink: 0;
            align-items: flex-start;
            border-top-right-radius: var(--quarter-radius);
            .user-jersey {
                padding: 6.5%;
                transform: translate(25%, -27%);
                &::after {
                    animation: 10s rotateRev 1s infinite linear;
                }
            }
            .jersey {
                &-view {
                    overflow: hidden;
                }
                &-img {
                    transform: translateY(2rem);
                    &-wrap {
                        padding: 14rem 11rem 0rem 3rem;
                        margin-bottom: -2rem;
                    }
                }
                &-wrapper {
                    top: 47%;
                    margin-left: 30%;
                }
            }
        }
    }
    // fav club section
    .confirm-changes {
        display: none;
    }
    .favourite-player {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: .2rem solid hsl(var(--hsl-white)/0.5);
        &-wrap {
            border-bottom: 0;
            margin: 0;
            .player-image {
                width: 30rem;
                height: 30rem;
            }
        }
        &-info {
            max-width: unset;
            margin: auto 0 var(--space-5);
            .form-dropdown {
                min-width: 40rem;
            }
        }
        .control-label {
            font-size: 1.6rem;
        }
        .stats-item {
            transition: 300ms;
            &:hover {
                @include bg(secondary, 5);
                transform: translateX(5rem);
            }
        }
    }
    .fav-club-stats,
    .club-follow {
        .title {
            font-size: 1.4rem;
        }
    }
    .fav-club {
        &-list {
            grid-template-columns: repeat(6, 1fr);
        }
        &-item {
            width: 100%;
            height: 100%;
            display: grid;
            align-content: center;
            .name {
                font-size: 1.4rem;
                font-weight: 800;
            }
            .club-img {
                width: 9rem;
                height: 9rem;
                padding: var(--space-2);
            }
        }
        &-name {
            margin-top: var(--space-1);
        }
        &-stats {
            .stats {
                &-list {
                    grid-template-columns: 18rem 1fr 1fr 1fr;
                    gap: 2rem;
                }
                &-item {
                    padding: 1rem;
                    border-radius: var(--quarter-radius);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: var(--space-2);
                    &-fav {
                        width: 18rem;
                        padding: 0;
                    }
                    &-wdl {
                        .stats-data {
                            flex: 1;
                        }
                    }
                }
                &-text {
                    font-size: 1rem;
                }
                &-data {
                    flex: unset;
                }
                &-number {
                    font-size: 3.5rem;
                    padding: 0;
                }
                &-img {
                    width: 5rem;
                    height: 5rem;
                }
                &-image {
                    position: unset;
                }
            }
        }
    }
    .club-follow {
        .club {
            &-item {
                padding: var(--space-1);
                @include bg(white, 0.5);
            }
            &-text {
                display: block;
            }
            &-list {
                overflow: unset;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
            }
            &-logo {
                background-color: transparent;
                width: 3.7rem;
                height: 3.7rem;
            }
        }
    }
    .edit-profile {
        .club-follow {
            .club {
                &-logo {
                    display: none;
                }
                &-item {
                    padding: var(--space-2);
                    .checkmark {
                        position: unset;
                        @include border(2, white, 1);
                        @include bg(white, 0.5);
                    }
                }
            }
        }
    }
}