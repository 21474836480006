@use '../config/' as *;
.modal {
    &-window {
        &.modal-takeover-banner {
            background-image: unset;
            @extend %secondary-dark-bg-8;
            @extend %d-none;
            &.active {
                @extend %flex-c-c;
            }
            .modal {
                &-body {
                    height: auto;
                    @extend %w-100;
                    @extend %py-0;
                    @extend %px-3;
                    @extend %relative;
                }
            }
            .banner-link {
                @extend %w-100;
            }
            .btn-close {
                width: 2.5rem;
                height: 2.5rem;
                aspect-ratio: 1;
                @include position(-1rem, 0.5rem);
                @extend %circle-radius;
                @extend %white-bg;
                @extend %flex-c-c;
                &::after {
                    @include icon(close, 10);
                    @extend %secondary-dark;
                }
            }
        }
        position: fixed;
        top: 0;
        left: 0;
        z-index: var(--z-modal);
        background-size: cover;
        border: 0;
        flex-direction: column;
        justify-content: center;
        @include bgImg('login/bg-pattern.png');
        @extend %w-100;
        @extend %h-100;
        @extend %secondary-dark-bg;
    }
    &-head {
        height: var(--modal-head-height);
        @extend %p-3;
        @extend %flex-sb-c;
        .btn-close {
            position: fixed;
            top: var(--space-2);
            right: var(--space-2);
            &::after {
                @include icon(close, 20);
                @extend %white;
            }
        }
        .btn-back {
            position: fixed;
            top: var(--space-2);
            right: var(--space-2);
            @extend %font-20-pb;
            @extend %white;
            @extend %flex-c-c;
            &::before {
                @include icon(chevron-left, 18);
                @extend %white;
            }
        }
    }
    &-body {
        height: var(--modal-body-height);
        @extend %flex-c-c;
        @extend %p-2;
        &-wrap {
            max-height: calc(var(--modal-body-height) - 5rem);
            overflow: auto;
            @include custom-scroll();
            @extend %w-100;
            @extend %white-bg;
            @extend %secondary;
            @extend %half-radius;
            @extend %p-3;
        }
    }
    &-title {
        @extend %font-18-pm;
    }
}
.modal-open {
    .header-wrap {
        z-index: var(--z-negative);
    }
    .footer-wrap {
        position: relative;
        z-index: var(--z-negative);
    }
    .step-section {
        z-index: var(--z-negative);
    }
}
@media (min-width:$tablet-min-width) {
    .modal {
        &-window {
            &.modal-takeover-banner {
                .modal-body {
                    max-width: 70rem;
                    padding-inline: 0;
                }
                .banner-link {
                    width: auto;
                }
                .btn-close {
                    right: -1rem;
                }
            }
        }
        &-title {
            font-size: 2.6rem;
            font-weight: 800;
            margin-right: var(--space-5);
        }
        &-head {
            &::after {
                font-size: 2.8rem;
            }
            .btn-close {
                top: var(--space-4);
                right: var(--space-6);
            }
            .btn-back {
                top: var(--space-4);
                left: var(--space-6);
            }
        }
        &-body-wrap {
            padding: var(--space-6) var(--space-10) var(--space-10) var(--space-10);
        }
    }
}