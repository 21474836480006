@use '../config/' as *;
// styles for user-profile
.load-bg-pattern {
    // html{
    .form-list::after {
        @include bgImg("login/pattern.svg");
    }
    .form-wrapper::after {
        @include bgImg("svg/hexagon.svg");
    }
}
// styles common in forms 
.waf-login,
.waf-complete-profile,
.waf-profile {
    padding: var(--space-4) var(--space-0);
    min-height: calc(var(--window-inner-height) - var(--header-height));
    @extend %flex-column-c-c;
    @extend %white;
    @extend %secondary-bg;
    @extend %mx-2-neg;
}
// form related common style
.form {
    &-title {
        @extend %font-18-pb;
        @extend %m-0;
    }
    &-area {
        @extend %hidden;
    }
    &-banner {
        @extend %white-bg;
        @extend %flex-c-c;
        @extend %pt-12;
        .pattern {
            @extend %w-60;
            @extend %relative;
            &-img {
                @extend %w-100;
            }
        }
        .step {
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 0;
            animation: fading 1.5s 2s forwards;
            @extend %w-100;
            @extend %h-100;
            @extend %position-t-l;
        }
        @for $number from 2 through 7 {
            .step-#{$number} {
                animation-delay: ($number * .5s) + 2s;
                @include bgImg("login/step-#{$number}.svg");
            }
        }
    }
    &-control {
        height: var(--input-height);
        background-color: hsl(var(--hsl-white)/0.05);
        color-scheme: dark;
        @include backdropBlur(.5rem);
        @include border(1, white, 2);
        @extend %white;
        @extend %w-100;
        @extend %px-3;
        @extend %quarter-radius;
    }
    &-element {
        display: flex;
        flex-direction: column-reverse;
    }
    &-sub-title {
        @extend %accent;
        @extend %font-12;
    }
    &-head {
        @extend %mb-3;
    }
    &-body {
        @extend %mt-6;
    }
    &-group {
        @extend %mb-4;
        .title {
            @extend %mb-0;
            @extend %font-16;
        }
    }
    &-wrap-group {
        @extend %mb-4;
    }
    &-error {
        .form-element {
            .form-control {
                border-color: var(--error);
            }
        }
    }
    &-select-box {
        @extend %relative;
    }
    &-otp {
        max-width: 28rem;
        .text {
            @extend %white-6;
        }
    }
    &-wrapper {
        @extend %py-6;
        &::after {
            content: "";
            content: "";
            width: 30rem;
            height: 30rem;
            background-size: cover;
            opacity: 0.1;
            background-position: bottom;
            transform: translate(50%, -50%);
            filter: brightness(0) invert(1);
            @extend %position-t-r;
        }
    }
    &-list {
        isolation: isolate;
        background: linear-gradient(126.11deg, var(--secondary-dark) 0%, var(--secondary-dark) 48.06%, var(--primary) 189.4%);
        @extend %p-12-6;
        @extend %relative;
        &::after {
            content: "";
            height: 7rem;
            background-size: cover;
            background-position: bottom;
            @extend %position-t-l;
            @extend %w-100;
        }
        &::before {
            content: "";
            transform: scaleY(-1) scaleX(-1);
            // transform: scaleY(-1) scaleX(-1);
            height: 7rem;
            background-size: cover;
            background-position: bottom;
            @include bgImg("login/pattern.svg");
            @extend %position-b-l;
            @extend %w-100;
        }
    }
    &-mail {
        @extend %relative;
        .btn-verify {
            position: absolute;
            top: var(--space-7);
            right: var(--space-2);
            border-radius: .3rem;
            @extend %primary;
            @extend %white-bg;
            @extend %p-1-2;
            @extend %font-12-pb;
            @extend %uppercase;
        }
        .form-control {
            padding-right: 9rem;
        }
        .form-element {
            @extend %relative;
        }
        .check {
            width: 2rem;
            height: 2rem;
            position: absolute;
            top: 3.5rem;
            right: 1rem;
            @extend %circle-radius;
            @extend %white;
            @extend %flex-c-c;
        }
        &.success {
            .check {
                @include border(1, white);
                @extend %success-bg;
                &::before {
                    @include icon(checkmark, 12);
                }
            }
        }
        &.error {
            .check {
                @include border(1, white);
                @extend %error-bg;
                &::before {
                    @include icon(close, 8);
                }
            }
        }
    }
    &-dob {
        .text {
            opacity: 0.6;
            @extend %font-16-pb;
        }
        .form-element {
            flex-direction: column-reverse;
        }
    }
    &-actions {
        max-width: 58rem;
        min-height: 40rem;
        @extend %d-none;
        @extend %m-auto;
        @extend %quarter-radius;
        @extend %secondary;
        @extend %p-3;
        @extend %text-center;
        @extend %light-grey-bg;
        .icon {
            width: 10rem;
            height: 10rem;
            @extend %mx-auto;
            @extend %my-4;
        }
        .title {
            line-height: 1.2;
            @extend %font-18-pb;
        }
        .content {
            @extend %mb-2;
            @extend %font-14-pr;
        }
        .btn-group {
            max-width: 30rem;
            @extend %flex-c-n;
            @extend %gap-2;
            @extend %mx-auto;
        }
        .btn-outline,
        .btn-fill {
            flex: 1;
            min-width: 14rem;
            @extend %mt-4;
            @extend %mb-2;
        }
        .btn-outline {
            border-color: var(--secondary);
            @extend %secondary;
        }
        &.active {
            @extend %flex-column-c-n;
        }
    }
}
select {
    option {
        @extend %black;
    }
}
// checkbox
.checkbox-container {
    @extend %flex-n-fs;
    @extend %gap-2;
    input[type=checkbox] {
        @extend %d-none;
        &:checked ~ .checkmark {
            &::after {
                @include icon(checkmark, 12);
                @extend %primary;
            }
        }
    }
}
.checkmark {
    width: 1.6rem;
    height: 1.6rem;
    flex-shrink: 0;
    border-radius: .3rem;
    margin-top: .3rem;
    @extend %white-bg;
    @extend %flex-c-c;
}
// radio buttons
.radio {
    &-container {
        @extend %flex-n-c;
        @extend %gap-1;
        input[type=radio] {
            @extend %d-none;
            &:checked {
                & ~ .text {
                    font-weight: 700;
                    @extend %accent;
                }
                & ~ .radiomark {
                    border-color: var(--accent);
                    &:after {
                        @extend %accent-bg;
                    }
                }
            }
        }
        .radiomark {
            width: 1.6rem;
            height: 1.6rem;
            flex-shrink: 0;
            @include border(1, white, 6);
            @extend %circle-radius;
            @extend %flex-c-c;
            &::after {
                content: "";
                flex-shrink: 0;
                width: 0.8rem;
                height: 0.8rem;
                @extend %circle-radius;
            }
        }
    }
    &-box {
        &-wrap {
            @extend %flex-wrap;
            @extend %mt-2;
            @extend %gap-4;
            @extend %flex;
        }
    }
}
// label style
.control-label {
    @extend %font-12;
    @extend %white-8;
    @extend %mb-1;
}
// mobile number field which has country dropdown in it
.mobile-number-wrapper {
    display: flex;
    flex-direction: column-reverse;
}
// button of verify or send otp
.send-otp {
    @extend %mb-4;
}
// wherever there is link 
.link-text {
    text-decoration: underline;
    @extend %accent;
}
// messages for error or success
.error-msg {
    @extend %font-10;
    @extend %error;
}
.success-msg {
    @extend %font-10;
    @extend %success;
}
.global-msg {
    background-color: hsl(var(--hsl-white)/0.05);
    @include backdropBlur(.5rem);
    @extend %w-100;
    @extend %mb-3;
    @extend %pr-4;
    @extend %pl-6;
    @extend %py-2;
    &:empty {
        @extend %d-none;
    }
    .message {
        @extend %my-1;
        &::marker {
            @extend %white;
        }
    }
}
.error .message {
    @extend %error;
}
// specifically targeted which are used otherwhere 
.custom-form {
    @extend %flex-wrap;
    @extend %gap-2;
    @extend %flex;
    .seperator {
        @extend %mb-4;
    }
    .mobile-otp {
        @extend %mb-0;
    }
}
// country code dropdown also can be used in other dropdowns
.country-flag {
    width: 2rem;
    height: 2rem;
    aspect-ratio: 1/1;
    @extend %circle-radius;
}
.selected-title {
    height: var(--input-height);
    background-color: hsl(var(--hsl-white)/0.05);
    @include backdropBlur(.5rem);
    @extend %text-left;
    @extend %w-100;
    @extend %flex-sb-c;
    @extend %gap-2;
    @extend %px-2;
    @extend %white;
    &::after {
        @include icon(chevron-down, 12, 16);
        @extend %transition;
    }
    &[aria-expanded=true] {
        &::after {
            transform: rotate(180deg);
        }
        & ~ .select-list {
            @include dropdown(open);
        }
    }
    > .btn-text {
        @extend %w-90;
        @extend %text-left;
        @extend %font-14-pm;
    }
}
.select-list {
    position: absolute;
    top: 100%;
    left: 0;
    list-style: none;
    max-height: 30rem;
    z-index: var(--z-select);
    @include dropdown(close);
    @include backdropBlur(2rem);
    @extend %y-auto;
    @extend %px-2;
    @extend %quarter-radius;
    @extend %w-100;
    @extend %white-bg-8;
    @extend %secondary;
}
.list-item {
    @extend %flex;
    @extend %gap-2;
    @extend %py-2;
    @extend %w-100;
    &.active {
        &::before {
            width: 2rem;
            height: 2rem;
            order: 1;
            @include border(1, primary);
            @include icon(checkmark);
            @extend %flex-c-c;
            @extend %ml-auto;
            @extend %circle-radius;
            @extend %primary;
        }
        .btn-text {
            font-weight: 700;
        }
        @extend %primary;
    }
    .btn-text {
        flex: 1;
        @extend %text-left;
        @extend %font-14-pm;
    }
    &:not(:last-child) {
        position: relative;
        @include separator(100%, x, .1rem, black)
    }
}
// input mobile field 
.mobile-number {
    position: relative;
    .form-control {
        padding-left: calc(var(--country-width) + var(--space-2));
        letter-spacing: .3rem;
        @include border(1, white, 2);
        @extend %p-2;
        @extend %white;
        @extend %font-18-pm;
        &::placeholder {
            letter-spacing: .1rem;
            @extend %font-14-pr;
        }
    }
    .form-select-box {
        width: var(--country-width);
        z-index: var(--z-country);
        @include separator(70%, y);
        position: absolute;
        bottom: 0;
        left: 0;
        &::after {
            left: var(--country-width);
        }
    }
    .selected-title {
        border: 0;
        @include backdropBlur(0);
        @extend %transparent-bg;
        .btn-text {
            width: 5rem;
            @extend %text-center;
        }
    }
    .select-list {
        width: calc(var(--window-inner-width) - var(--space-12));
    }
    &-wrapper {
        @extend %relative;
    }
}
// content section
.content {
    &-title {
        @extend %mb-0;
        @extend %font-18-pb;
        @extend %uppercase;
    }
    &-sub-title {
        @extend %font-12;
        @extend %white-8;
    }
    &-badge {
        width: max-content;
        max-width: 100%;
        @extend %white-bg-2;
        @extend %capitalize;
        @extend %mb-2;
        @extend %p-1-2;
        &-list {
            @extend %flex-column;
            @extend %mt-4;
        }
    }
}
// otp section 
.otp {
    &-wrap {
        width: max-content;
        @extend %relative;
        @extend %flex;
        @extend %gap-1;
        .form-control {
            width: 3.5rem;
            height: 3.5rem;
            border: 0;
            @extend %text-center;
            @extend %font-18;
            @extend %white-bg;
            @extend %hidden;
            @extend %secondary;
            @extend %p-0;
        }
        .check {
            width: 2rem;
            height: 2rem;
            right: -3rem;
            @extend %position-y-center;
            @extend %circle-radius;
            @extend %white;
            @extend %flex-c-c;
        }
        &.success {
            .check {
                @include border(1, white);
                @extend %success-bg;
                &::before {
                    @include icon(checkmark, 12);
                }
            }
        }
        &.error {
            .check {
                @include border(1, white);
                @extend %error-bg;
                &::before {
                    @include icon(close, 8);
                }
            }
        }
    }
}
.resend-otp {
    .text {
        @extend %font-12;
    }
    .otp {
        text-decoration: underline;
        @extend %font-14-pb;
        @extend %accent;
    }
    .timer {
        @extend %white-6;
        @extend %font-10;
    }
}
// jersey 
.jersey {
    &-wrapper {
        position: absolute;
        top: 22%;
        inset-inline: 0;
        @extend %m-auto;
        @extend %text-center;
    }
    &-name {
        @extend %font-12-pm;
        @extend %uppercase;
        @extend %mb-0;
    }
    &-number {
        @extend %font-30-pb;
    }
    &-img {
        width: 25rem;
        width: 25rem;
        aspect-ratio: 1/1;
        object-fit: contain;
        @extend %p-6;
        ;
    }
    &-view {
        background: linear-gradient(180.59deg, hsl(var(--hsl-secondary)/0.2) 11.76%, transparent 85.4%);
        @extend %relative;
        @extend %circle-radius;
    }
}
// club section
.club {
    &-label {
        @extend %accent;
        @extend %font-14-pb;
        @extend %w-100;
        @extend %d-block;
    }
    &-list {
        @extend %my-2;
        @include grid(2, var(--space-2))
    }
    &-item {
        @include border(1, white, 2);
        @extend %p-2;
        @extend %flex-n-c;
        @extend %gap-2;
        input[type=checkbox] {
            @extend %d-none;
            &:checked ~ .checkmark {
                @extend %primary-bg;
                @include border(1, primary);
                &::after {
                    @include icon(checkmark, 12);
                    @extend %white;
                }
            }
        }
        .checkmark {
            width: 2rem;
            height: 2rem;
            box-shadow: 0px 0px .2rem .2rem hsl(var(--hsl-black)/0.25) inset;
            @include border(1, white);
            @extend %white-bg-2;
            @extend %circle-radius;
        }
    }
}
@include mq(col-md) {
    // styles for user-profile
    .load-bg-pattern {
        // html {
        .waf-login,
        .waf-complete-profile,
        .waf-profile {
            @include bgImg('login/wrapper-bg.png');
        }
        .form-list::after {
            @include bgImg("login/bg-d.svg");
        }
    }
    // form style 
    .mobile-number .select-list {
        width: 30rem;
    }
    .form {
        &-list,
        &-banner {
            max-height: calc(var(--window-inner-height) - var(--header-height) - var(--space-12));
            padding: var(--space-6);
            min-height: 40rem;
        }
        &-list {
            background: var(--secondary-light);
            margin: 0;
            margin-left: var(--space-6);
            padding-left: var(--space-8);
            flex: 1;
            &::after {
                width: auto;
                height: unset;
                inset: 0 -4rem -2rem -15rem;
                background-size: cover;
                background-position: left;
                z-index: var(--z-negative);
            }
            &::before {
                display: none;
            }
        }
        &-wrapper {
            margin-left: var(--space-6-neg);
            overflow: auto;
            height: 100%;
            @include custom-scroll()
        }
        &-area {
            display: flex;
            background: var(--white);
            border-radius: var(--full-radius);
            overflow: hidden;
            position: relative;
        }
        &-banner {
            width: 50%;
            flex-shrink: 0;
            max-width: 40rem;
        }
        &-wrap-group {
            display: grid;
            grid-template-columns: 1fr 1fr;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: var(--space-4);
            margin-bottom: var(--space-4);
            margin-right: var(--space-10);
            .form-group {
                margin-bottom: 0;
            }
        }
        &-group {
            .title {
                font-size: 1.4rem;
            }
            .btn-fill {
                padding-block: var(--space-3);
            }
        }
        &-head {
            margin-bottom: var(--space-1);
        }
        &-otp {
            max-width: 100%;
            order: 1;
        }
        &-title {
            font-size: 2.2rem;
        }
        &-actions {
            padding: var(--space-10);
            .icon {
                width: 12.6rem;
                height: 12.6rem;
            }
        }
    }
    .content-title {
        font-size: 1.6rem;
    }
    .email-mobile-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
        gap: var(--space-2);
    }
    // jersey section 
    .jersey {
        &-img {
            width: 32rem;
            width: 32rem;
        }
    }
    .otp-wrap {
        .form-control {
            width: 4.5rem;
            height: 4.5rem;
            font-size: 2.2rem;
        }
    }
    // club section 
    .club-list {
        @include grid(3, var(--space-3))
    }
    // styles common in forms 
    .waf-login,
    .waf-complete-profile,
    .waf-profile {
        background-color: var(--secondary-dark);
        background-image: linear-gradient(126.11deg, var(--secondary-dark) 0%, var(--secondary-dark) 48.06%, var(--primary) 189.4%);
        background-attachment: fixed;
        background-size: cover;
        height: calc(var(--window-inner-height) - var(--header-height));
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 47rem;
        padding: var(--space-6) 0;
        .resend-otp {
            order: 1;
        }
        .otp-input {
            padding-top: var(--space-5);
        }
    }
}
@media (min-width:$tablet-min-width) and (max-width:$tablet-max-width) {
    .waf-profile {
        .form-group {
            width: 100%;
        }
    }
    .waf-login {
        .form-wrapper {
            margin-left: 0;
        }
        .form-wrap-group {
            grid-template-columns: 1fr;
        }
    }
    .waf-login,
    .waf-complete-profile,
    .waf-profile {
        height: unset;
    }
    .form-area {
        flex-direction: column;
        .form {
            &-banner,
            &-list {
                width: 100%;
                margin: 0;
                max-height: unset;
            }
            &-banner {
                margin: auto;
            }
        }
    }
}