@use "../config/" as *;
$trending-icon: 4rem;
$navLink: 4rem;
@include hamburger;
// load-bg-pattern
html {
  .header-wrap .site-nav::after {
    @include bgImg("svg/isl-navbg.svg");
  }
}
// load-bg-pattern
.m-header {
  .promo-list-wrap {
    @extend %py-3;
    &:not(:last-child) {
      @include border(1, black, 5, bottom);
    }
  }
  .club-site {
    &-list {
      flex-wrap: wrap;
    }
    &-label {
      @extend %d-none;
    }
    &-item:not(:last-child) {
      @include border(1, black, 1, bottom);
    }
  }
  .btn-hamburger {
    &[aria-expanded="true"] {
      z-index: var(--z-hamburger);
      transform: translateY(-4rem);
    }
  }
  .site {
    &-search {
      @extend %ml-auto;
    }
    // &-login {
    //   @extend %ml-auto;
    // }
    &-nav-right {
      box-shadow: -0.2rem 0 0.4rem 0.2rem hsl(var(--hsl-black) / 0.1);
    }
  }
  .promo-section {
    height: var(--promo-height);
    @extend %flex-column-c-n;
    @extend %grey-bg;
  }
  .login-section {
    @extend %flex-c-c;
    .list-wrap {
      top: var(--header-height);
      right: 0;
      z-index: var(--z-negative);
      @extend %py-2;
      @extend %w-100;
      @extend %flex-c-c;
      @extend %grey-bg;
      .btn {
        @extend %relative;
        @extend %uppercase;
        @extend %px-4;
        &:not(:last-child) {
          border-bottom: 0;
          &::before {
            content: "";
            width: 0.1rem;
            height: 50%;
            right: -1rem;
            @extend %black-bg;
            @extend %position-y-center;
          }
        }
      }
      > :first-child {
        font-weight: 700;
        @extend %primary;
      }
    }
  }
  .nav-link {
    &:not(:last-child)::after {
      content: "";
      height: 0.1rem;
      bottom: 0;
      @extend %w-80;
      @extend %black-bg-1;
      @extend %position-x-center;
    }
    & > .nav-anchor {
      font-weight: 700;
      @extend %uppercase;
    }
  }
  .sub-ul .nav-anchor {
    height: 0;
  }
  .btn-hamburger[aria-expanded="true"]::before {
    @extend %d-none;
  }
}
.header {
  &-wrap {
    background-image: linear-gradient(to right,
        var(--secondary-light) 0%,
        var(--secondary) 50%,
        var(--secondary-dark) 100%);
    z-index: var(--z-header);
    position: fixed;
    top: 0;
    left: 0;
    height: var(--header-height);
    @extend %transition;
    @extend %w-100;
    ul {
      list-style-type: none;
    }
  }
  &-top {
    height: var(--header-top);
    @extend %grey-bg;
    @extend %flex;
    // showcase widget
    .waf-showcase {
      @extend %p-0;
      @extend %mb-0;
      @extend %relative;
      @extend %flex-n-c;
      &::before {
        content: unset;
        height: var(--header-top);
        @extend %font-12-pb;
        @extend %pl-3;
        @extend %grey-bg;
        @extend %flex-n-c;
      }
    }
    .layout-wrapper {
      max-width: unset;
      @extend %relative;
      @extend %hidden;
      @extend %flex-n-c;
      &::before {
        height: var(--header-top);
        flex-shrink: 0;
        z-index: var(--z-header-icon);
        @include icon(trend, 12);
        @extend %flex-c-c;
        @extend %grey-bg;
        @extend %pl-3;
        @extend %pr-2;
      }
      &::after {
        content: "";
        width: 0.1rem;
        height: 60%;
        left: 3.7rem;
        @extend %black-bg-1;
        @extend %position-y-center;
      }
    }
    .article {
      &-list {
        transform: translateX(32vw);
        animation: marquee 45s linear infinite;
        @extend %gap-4;
        @extend %flex;
        @extend %pl-2;
        &:hover {
          animation-play-state: paused;
        }
      }
      &-item {
        width: max-content;
        flex-shrink: 0;
      }
      &-content {
        @extend %p-0;
      }
      &-title {
        width: max-content;
        height: unset;
        @extend %mb-0;
        @extend %font-12-pm;
        @extend %flex-n-c;
        &::before {
          content: "";
          width: 0.7rem;
          aspect-ratio: 1/1;
          flex-shrink: 0;
          @extend %mr-1;
          @extend %circle-radius;
          @extend %error-bg;
        }
      }
    }
  }
  &-bottom {
    box-shadow: 0 0.2rem 0.5rem var(--black);
    height: var(--header-bottom);
    @extend %gap-4;
    @extend %flex;
  }
}
.trending-section {
  @extend %w-100;
  @extend %hidden;
}
.lang-section {
  width: var(--lang-section-width);
  flex-shrink: 0;
  z-index: var(--z-lang);
  @extend %relative;
  button,
  a {
    @extend %w-100;
  }
}
.btn {
  &-lang {
    height: var(--header-top);
    @extend %font-12-pm;
    @extend %p-1;
    @extend %relative;
    @extend %gap-1;
    @extend %flex-c-c;
    @extend %accent-bg;
    .icon {
      width: 1rem;
      &::before {
        @include icon(internet, 12);
      }
    }
    &::after {
      transform-origin: center;
      @include icon(chevron-down, 12);
      @extend %transition;
    }
    &[aria-expanded="true"] {
      &::after {
        transform: rotate(180deg);
      }
      & ~ .list-wrap {
        @include dropdown(open);
      }
    }
    .btn-text {
      width: 2.2rem;
      @extend %hidden;
    }
  }
  &-search {
    .btn-text {
      &::before {
        @include icon(search, 16);
        @extend %white;
      }
    }
    &[aria-expanded="true"] {
      & ~ .search-input-wrap {
        @include dropdown(open);
      }
      .btn-text {
        &::before {
          @include icon(close);
        }
      }
    }
  }
  &-login {
    width: 3.2rem;
    height: 3.2rem;
    aspect-ratio: 1/1;
    margin-left: auto;
    @extend %circle-radius;
    @extend %hidden;
    @extend %primary-bg;
    .close-icon {
      @extend %d-none;
      &::before {
        @extend %white;
        @include icon(close);
      }
    }
    .user-photo {
      @extend %circle-radius;
    }
    .btn-text {
      @extend %d-none;
    }
    &[aria-expanded="true"] {
      .close-icon {
        @extend %d-block;
      }
      .user-photo {
        @extend %d-none;
      }
      & ~ .list-wrap {
        @include dropdown(open);
      }
    }
  }
  &-text {
    @extend %font-12;
  }
  &-hamburger {
    @extend %flex-c-c;
    @extend %uppercase;
    @extend %transition;
    &[aria-expanded="true"] {
      & ~ .site-nav {
        transform: translateY(0);
        pointer-events: initial;
        opacity: 1;
      }
    }
    &::after {
      content: unset;
      @extend %uppercase;
      @extend %accent;
    }
    .btn-text {
      @extend %font-0;
    }
  }
}
.list-wrap {
  position: absolute;
  top: var(--header-top);
  right: 0;
  min-width: var(--lang-section-width);
  @include dropdown(close);
  @extend %grey-bg;
  .btn {
    @extend %d-block;
    @extend %mx-2;
    @extend %py-1;
    @extend %font-12-pm;
    &:not(:last-child) {
      @include border(1, dark-grey, 6, bottom);
    }
    &.active {
      .btn-text {
        @extend %primary;
      }
    }
  }
}
.logo {
  width: var(--logo-width);
  height: var(--logo-height);
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0 0 50vmax 50vmax;
  box-shadow: 0 0.2rem 0.5rem -0.25rem hsl(var(--hsl-black) / 0.3);
  @include bgImg("svg/isl-logo.svg");
  @extend %ml-3;
  @extend %secondary-light-bg;
  @extend %d-block;
  &-text {
    @extend %font-0;
  }
}
.search {
  &-input {
    border: 0;
    padding-left: 11.5rem;
    @extend %font-16;
    @extend %w-100;
    @extend %p-4-3;
    @extend %transparent-bg;
    @extend %light-grey;
    &-wrap {
      position: absolute;
      top: var(--header-height);
      left: 0;
      z-index: var(--z-negative);
      @include dropdown(close);
      @extend %secondary-dark-bg;
      @extend %w-100;
      .search {
        &-list {
          list-style: none;
          padding-left: 0;
          @include border(1, white, 5, top);
          @extend %secondary-dark-bg;
          @extend %white;
        }
        &-item {
          padding-left: 9rem;
          @extend %py-3;
          @extend %pr-3;
          @extend %font-14;
          &:hover {
            font-weight: 700;
            @extend %white-bg-1;
          }
        }
      }
    }
    &::placeholder {
      opacity: 0;
    }
    &:focus + .search-label,
    &:not(:placeholder-shown) ~ .search-label,
    &.active {
      font-size: 0;
      &::before {
        font-size: 1.4rem;
      }
    }
  }
  &-label {
    position: absolute;
    inset: 0;
    height: 6rem;
    pointer-events: none;
    padding-left: 9rem;
    @extend %light-grey-4;
    @extend %flex-fs-c;
    @extend %gap-2;
    @extend %font-14;
    &::before {
      @include icon(search);
    }
  }
}
.login-section {
  @extend %flex-c-c;
}
.partner {
  &-section {
    width: max-content;
    border-radius: 0 50vmax 50vmax 0;
    @extend %secondary-dark-bg;
    @extend %flex-n-c;
    @extend %gap-4;
    @extend %p-2-4;
    .item {
      height: 2.2rem;
      @extend %relative;
      &:not(:last-child) {
        @extend %relative;
        &::after {
          content: "";
          width: 0.1rem;
          right: -1rem;
          @extend %h-100;
          @extend %position-t-r;
          @extend %white-bg-5;
        }
      }
    }
    .btn-partners {
      display: inline-block;
      @extend %h-100;
    }
    img {
      width: auto;
      height: 100%;
    }
    .list-wrap {
      top: 3.4rem;
      @extend %flex-c-n;
      @extend %gap-1;
      .btn {
        border: 0;
        @extend %relative;
        &-text {
          font-size: 2rem;
        }
        &:not(:last-child) {
          content: "";
          @include separator(80%, y, black);
        }
      }
    }
    .btn-partners[aria-expanded="true"] ~ .list-wrap {
      @include dropdown(open);
    }
    .btn {
      @extend %text-center;
      &-text {
        font-family: $font-icon;
      }
    }
  }
}
.club {
  &-image {
    width: 3rem;
    flex-shrink: 0;
  }
  &-info {
    @extend %flex-n-c;
    @extend %gap-2;
  }
  &-name {
    @extend %font-10-pb;
    @extend %uppercase;
  }
  &-site {
    &-section {
      @extend %flex;
      @extend %gap-2;
      @extend %h-100;
    }
    &-label {
      opacity: 0.7;
      @extend %font-12;
      @extend %flex-n-c;
      @extend %gap-1;
      @extend %grey;
      @extend %capitalize;
      &::after {
        @include icon(trend, 12);
      }
    }
    &-list {
      @extend %p-1-3;
      @extend %h-100;
      @extend %flex-c-n;
    }
    &-item {
      break-inside: avoid-column;
      @extend %w-100;
      @extend %py-1;
      @extend %flex-column-c-fs;
    }
    &-wrapper {
      @extend %h-100;
    }
  }
}
.header-wrap {
  .site {
    &-search {
      @extend %flex-n-c;
    }
    &-login {
      @extend %flex-n-c;
    }
    &-nav {
      z-index: var(--z-over-menu);
      height: var(--window-inner-height);
      position: fixed;
      top: 0;
      right: 0;
      pointer-events: none;
      opacity: 0;
      transform: translateY(-100%);
      @extend %transition;
      @extend %w-100;
      @extend %light-grey-bg;
      &-top {
        height: var(--site-nav-top);
        @extend %secondary-bg;
        @extend %py-1;
      }
      &-bottom {
        height: calc(100% - var(--site-nav-top));
        @extend %flex-n-n;
      }
      &-left {
        flex-shrink: 0;
        @extend %w-50;
      }
      &-right {
        flex-shrink: 0;
        @extend %w-50;
      }
    }
  }
  .nav {
    &-link {
      @extend %relative;
      &-list {
        height: calc(100% - var(--promo-height));
        @extend %y-auto;
        @extend %pl-0;
        @extend %pr-2;
      }
    }
    &-anchor {
      height: $navLink;
      @extend %px-3;
      @extend %w-100;
      @extend %text-left;
      @extend %d-block;
      @extend %capitalize;
      @extend %font-12-pm;
      @extend %flex-sb-c;
    }
  }
  .active {
    > .nav-anchor {
      font-weight: 700;
      @extend %relative;
      @extend %primary;
      &::before {
        content: "";
        width: 0.3rem;
        z-index: var(--z-negative);
        left: 0;
        @extend %h-80;
        @extend %position-y-center;
        @extend %primary-bg;
      }
    }
  }
  button.nav-anchor {
    @extend %relative;
    &::after {
      @include icon(chevron-down);
      @extend %transition;
    }
    &::before {
      content: "";
      width: 0rem;
      z-index: var(--z-negative);
      left: 0;
      @extend %h-80;
      @extend %position-y-center;
      @extend %primary-bg;
      @extend %transition;
    }
    & ~ .sub-ul {
      &::after {
        content: "";
        width: 0;
        height: 0.3rem;
        @extend %rounded-radius;
        @extend %transition;
        @extend %position-b-l;
        @extend %primary-bg;
      }
    }
    &[aria-expanded="true"] {
      @extend %white;
      &::before {
        width: 100%;
      }
      &::after {
        transform: rotate(180deg);
        transform-origin: center;
      }
      & ~ .sub-ul {
        > .sub-nav-link {
          > .nav-anchor {
            height: $navLink;
          }
        }
        &::after {
          @extend %w-80;
        }
      }
    }
  }
  .sub-ul {
    @extend %px-0;
    .nav-anchor {
      @extend %transition;
    }
    .sub-nav-link {
      @extend %transition;
      @extend %hidden;
    }
  }
  .secondary-sub-ul {
    @extend %pl-0;
    .nav-anchor {
      @extend %pl-6;
    }
  }
  .secondary-submenu {
    @extend %px-0;
    button.nav-anchor {
      @extend %secondary;
      &::before {
        @extend %d-none;
      }
      &::after {
        transform: rotate(135deg);
        @include icon(close);
        @extend %font-8;
      }
    }
    .nav-anchor[aria-expanded="true"] {
      @extend %primary;
      & ~ .secondary-sub-ul .nav-anchor {
        height: $navLink;
      }
    }
  }
}
.promo {
  &-label {
    @extend %uppercase;
  }
  &-text {
    @extend %font-0;
  }
  &-list {
    list-style: none;
    @extend %p-0;
    @extend %gap-2;
    @extend %flex;
  }
  &-anchor {
    @extend %font-16;
  }
  &-section {
    @extend %px-2;
  }
}
@media (min-width: $tablet-min-width) {
  .promo {
    &-section {
      display: flex;
      gap: var(--space-2);
    }
    &-list {
      &-wrap {
        padding: 0;
        display: flex;
        align-items: center;
        width: max-content;
        gap: var(--space-2);
        &:last-child {
          padding-left: var(--space-1);
          &::before {
            content: "";
            width: 0.1rem;
            height: 60%;
            background-color: hsl(var(--hsl-black) / 0.1);
          }
          .promo-label {
            display: none;
          }
        }
      }
    }
  }
  .btn-hamburger {
    margin-inline: auto var(--space-4);
    &::before {
      left: unset;
      right: -2rem;
    }
    &[aria-expanded="true"] ~ .club-site-wrapper {
      transform: translateY(-50%);
      opacity: 0;
      pointer-events: none;
    }
    &[aria-expanded="true"] {
      flex-direction: row-reverse;
      &::after {
        content: "close";
      }
      .line {
        stroke: var(--accent);
      }
      svg {
        width: 2.8rem;
        height: 2.8rem;
      }
    }
  }
  .site-logo {
    order: -1;
  }
  .club-site-wrapper {
    overflow: auto;
    order: -1;
    transition: 300ms;
  }
  .login-section {
    position: relative;
    text-transform: uppercase;
    .list-wrap {
      width: max-content;
      top: 100%;
      right: 0;
      > :first-child {
        font-weight: 600;
        color: var(--primary);
      }
    }
  }
  .list-wrap {
    min-width: 10rem;
    .btn {
      padding-block: var(--space-2);
    }
  }
  .partner-section {
    border-radius: 50vmax 0 0 50vmax;
    margin-block: var(--space-1);
    background-color: var(--secondary);
    .list-wrap {
      top: 4rem;
    }
    .item {
      height: 3.1rem;
    }
  }
  .club {
    &-name {
      display: none;
    }
    &-image {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      aspect-ratio: 1/1;
      padding: var(--space-1);
      @include bg(white);
    }
    &-site-list {
      gap: var(--space-2);
      padding-left: 0;
    }
  }
  .header-wrap {
    .site {
      &-login {
        margin-left: 0;
      }
      &-nav {
        padding: var(--space-8);
        top: var(--header-height);
        height: calc(var(--window-inner-height) - var(--header-height));
        z-index: var(--z-site);
        overflow-y: auto;
        overflow-x: 0;
        &::after {
          content: "";
          width: 30%;
          height: 80%;
          position: absolute;
          right: 4rem;
          top: 20%;
          opacity: 0.1;
          pointer-events: none;
          background-size: contain;
          background-repeat: no-repeat;
          filter: grayscale(1) brightness(0);
        }
        .label,
        .nav-link > .nav-anchor {
          opacity: 1;
          font-size: 1.6rem;
          font-weight: 700;
          display: block;
          height: unset;
          padding-bottom: var(--space-1);
          margin-bottom: var(--space-4);
          text-transform: uppercase;
          color: var(--secondary);
          position: relative;
          @include border(1, secondary, 4, bottom);
          &:after {
            content: "";
            width: 20%;
            height: 0.3rem;
            position: absolute;
            bottom: -0.2rem;
            left: 0;
            border-radius: 50vmax;
            background-color: var(--secondary);
            transition: 300ms;
          }
        }
        .active {
          > .nav-anchor::before {
            display: none;
          }
        }
        &-bottom {
          height: 100%;
          gap: var(--space-12);
          max-width: var(--container-max-width);
          margin: auto;
        }
        &-left,
        &-right {
          width: calc(50% - var(--space-6));
        }
        .club {
          &-name {
            display: block;
            font-size: 1.4rem;
            font-weight: 400;
          }
          &-anchor {
            width: 100%;
          }
          &-site {
            &-section {
              flex-direction: column;
            }
            &-list {
              display: block;
              padding-inline: 0;
            }
          }
          &-image {
            width: 4rem;
          }
          &-info {
            margin-bottom: var(--space-4);
          }
        }
        .nav {
          &-link {
            break-inside: avoid-column;
            margin-bottom: var(--space-7);
            &-list {
              height: max-content;
              column-gap: var(--space-10);
              overflow: hidden;
            }
          }
          &-anchor {
            height: 3rem;
            font-size: 1.4rem;
            padding-left: 0;
          }
        }
        .sub-ul {
          break-inside: avoid-column;
          &.active {
            .label {
              &:after {
                width: 80%;
                background-color: var(--primary);
              }
            }
          }
        }
        .secondary {
          &-sub-ul {
            padding-left: var(--space-2);
          }
          &-submenu {
            button.nav-anchor {
              height: 4rem;
              .btn-text {
                font-size: 1.4rem;
              }
              &:before {
                left: 0rem;
              }
            }
            .nav-anchor {
              height: 0;
            }
          }
        }
        .sub-ul {
          .nav-anchor {
            opacity: 0.6;
          }
        }
      }
    }
  }
  .header-bottom {
    > .club-site-section {
      overflow: auto;
      .club-site-list {
        overflow: auto;
        justify-content: start;
      }
    }
  }
  .header {
    &-top {
      .waf-showcase {
        &::before {
          content: "Trending";
        }
      }
      .layout-wrapper {
        &::before {
          padding-left: var(--space-1);
          padding-right: var(--space-3);
        }
        &::after {
          left: 3.2rem;
        }
      }
    }
  }
  .search-input-wrap {
    .search-input {
      padding-inline: 22rem;
    }
    .search-label {
      padding-left: 20rem;
    }
    .search-item {
      padding-inline: 20rem;
    }
  }
}
@media (min-width: $desktop-min-width) {
  .header-wrap .site-nav {
    .club-site-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: unset;
    }
    .nav-link-list {
      column-count: 2;
    }
  }
}