@use "../config/" as *;
.table {
    border: 0.1rem solid hsl(var(--hsl-pure-black) / .3);
    border-collapse: collapse;
    @extend %w-100;
    thead {
        @extend %secondary-bg;
        @extend %white;
    }
    th,
    td {
        @extend %capitalize;
        @extend %text-left;
        @extend %p-2;
    }
    tr {
        @extend %relative;
        &:not(:last-child) {
            border-bottom: 0.1rem solid hsl(var(--hsl-secondary) / .3);
        }
        &:nth-child(even) {
            @extend %secondary-bg-1;
        }
    }
    &-row {
        @extend %w-100;
        @extend %flex-c-c;
    }
    &-head {
        @extend %secondary-bg;
        .table-data {
            @extend %secondary-bg;
        }
    }
    &-left {
        @extend %w-40;
    }
    &-right {
        @extend %w-60;
    }
    &-data {
        flex-shrink: 1;
        height: 5rem;
        @extend %w-100;
        @extend %text-center;
        @extend %flex-c-c;
    }
    .text {
        @extend %white;
        @extend %font-14-pb;
        @extend %uppercase;
    }
}