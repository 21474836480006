@use '../config/' as *;
.waf-breadcrumb {
    height: var(--breadcrumb-height);
    z-index: var(--z-breadcrumb);
    // background-image: linear-gradient(to right, var(--secondary-dark) 60%, var(--primary) 120%);
    @extend %relative;
    @extend %flex-fs-c;
    &.waf-component {
        @extend %px-3;
        @extend %mb-0;
    }
    .layout-wrapper {
        @extend %w-100;
    }
    .breadcrumb {
        list-style-type: none;
        @extend %pl-0;
        @extend %d-block;
        &-item {
            display: inline;
            span {
                @extend %accent;
                @extend %font-12-pr;
            }
            &:last-child {
                span {
                    @extend %white-7;
                }
            }
            &:not(:first-child) {
                &::before {
                    @include icon(chevron-right, 12);
                    @extend %font-10;
                    @extend %mx-1;
                    @extend %white;
                }
            }
        }
    }
}
.club-page,
.breadcrumb-hidden {
    .waf-breadcrumb {
        height: 0;
        overflow: hidden;
        padding: 0;
        margin: 0;
    }
}
@include mq(col-md) {
    .waf-breadcrumb {
        padding-bottom: var(--space-2);
        align-items: flex-end;
        .layout-wrapper {
            margin-bottom: 0;
        }
    }
    .breadcrumb-half {
        .waf-breadcrumb {
            padding-inline: 0;
            .layout-wrapper {
                padding-inline: 12%
            }
        }
    }
}