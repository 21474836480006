@use "./map" as *;
// scss variables
$base-space: 0.5rem;
$max-space-count: 20; // maximum count variable generation
$default-line-height: 1.6;
$body-font-size: 1.4rem;
$body-font-weight: 400;
// media query variables
$small-mobile-min-width: 0px;
$small-mobile-max-width: 360px;
$medium-mobile-min-width: 576px;
$mobile-max-width: 767px;
$tablet-min-width: 768px;
$tablet-max-width: 991px;
$desktop-min-width: 992px;
$desktop-max-width: 1199px;
$large-desktop-min-width: 1200px;
$xl-desktop-min-width: 1600px;
// $gradient: linear-gradient(92.39deg, var(--club-primary) -55.83%, var(--club-list-gredient) 96.88%);
$gradient: linear-gradient(-92.39deg,
    var(--club-secondary) -55.83%,
    var(--club-primary) 96.88%);
// font variables
$font-primary: "Inter";
$font-icon: "waf-font-icon";
//  version variables
$image-version: "100.46";
$font-version: "53423735";
// css variables
:root {
  @each $color, $hsl in $colors {
    $h: nth($hsl, 1);
    $s: nth($hsl, 2);
    $l: nth($hsl, 3);
    --#{$color}: hsl(#{$h} #{$s} #{$l});
    --hsl-#{$color}: #{$h} #{$s} #{$l};
  }
  // non color variables
  --base-font-size: 62.5%;
  --container-max-width: 100%;
  --content-width: var(--window-inner-width);
  --vh: 1vh;
  --vw: 1vw;
  --window-inner-height: calc(var(--vh, 1vh) * 100);
  --window-inner-width: calc(var(--vw, 1vw) * 100);
  --container-white-space: calc((var(--window-inner-width) - var(--container-max-width)) / 2);
  --container-white-space-neg: calc(-1 * ((var(--window-inner-width) - var(--container-max-width)) / 2));
  // space variable
  @for $i from 0 through $max-space-count {
    --space-#{$i}: #{$base-space * $i};
    --space-#{$i}-neg: #{$base-space * $i * -1};
  }
  // use
  // --space-1 = 5px
  // --space-2 = 10px.... till --space-12 = 60px
  // this is according to base space variable
  // radius
  --full-radius: 1rem;
  --half-radius: calc(var(--full-radius) / 2);
  --quarter-radius: calc(var(--half-radius) / 2);
  --circle-radius: 50%;
  --rounded-radius: 50vmax;
  // ********** assending order *******************
  // width varibles
  --logo-width: 7.2rem;
  --lang-section-width: 8rem;
  --swiper-button-width: 3rem;
  --country-width: 12.5rem;
  // height varibles
  --logo-height: 8.5rem;
  --header-top: 3rem;
  --header-bottom: 6.5rem;
  --header-height: calc(var(--header-top) + var(--header-bottom));
  --footer-bottom-height: 3rem;
  --footer-top-height: 5rem;
  --site-nav-top: 5rem;
  --promo-height: 15rem;
  --club-size: 12.3rem;
  --quicklink-head-height: 4rem;
  --quicklink-body-height: 6.5rem;
  --quicklink-height: calc(var(--quicklink-head-height) + var(--quicklink-body-height));
  --secondary-header-height: 4rem;
  --input-height: 4.5rem;
  --breadcrumb-height: 8rem;
  --modal-head-height: 7rem;
  --modal-body-height: calc(var(--window-inner-height) - var(--modal-head-height));
  --sticky-scores: 5rem;
  --21by9-ratio: 0.4285;
  --extra-video-space: 5rem;
  --showcase-video-space: calc(var(--window-inner-width) + var(--extra-video-space));
  --scorestrip-toggle-height: 4rem;
  --filter-height: 7rem;
  //width for timeline progressbar
  --timeline-progressbar-width: 25%;
  //height for timeline
  --timeline-height: 13rem;
  // zindex variable
  @each $name, $value in $zindex {
    --z-#{$name}: #{$value};
  }
}
//safari workaround for base font size
@media only screen and (max-width: $tablet-min-width) {
  :root {
    -webkit-text-size-adjust: none;
  }
}
@media (min-width: $tablet-min-width) {
  :root {
    --container-max-width: 72rem;
    --quicklink-head-height: 3.5rem;
    --quicklink-body-height: 6rem;
    --secondary-header-height: 5rem;
    --logo-width: 8.8rem;
    --logo-height: 10.5rem;
    --sticky-scores: 8rem;
    --scorestrip-toggle-height: 2.5rem;
  }
}
@media (min-width: $desktop-min-width) {
  :root {
    --container-max-width: 96rem;
    --club-size: 15rem;
  }
}
@media (min-width: $large-desktop-min-width) {
  :root {
    --container-max-width: 114rem;
  }
}
@media (min-width: $xl-desktop-min-width) {
  :root {
    --container-max-width: 144rem;
  }
}