@use '../config/' as *;
.cookie-block {
    z-index: var(--z-modal);
    position: fixed;
    inset: 0;
    @extend %d-none;
    @extend %secondary-dark-bg-8;
    .cookie {
        &-wrap {
            @include position(null, var(--space-3), var(--space-3), var(--space-3));
            @extend %p-7-6;
            @extend %relative;
            @extend %accent-bg;
        }
        &-body {
            @extend %mb-5;
        }
    }
    .title {
        font-size: 2rem;
        @extend %secondary-dark;
    }
    .desc {
        font-size: 1.4rem;
    }
    .btn-site {
        border-radius: .2rem;
        @extend %w-100;
        @extend %secondary-light-bg;
        &:hover {
            @extend %secondary-light-bg;
            @extend %white;
        }
    }
    .load {
        font-weight: 400;
    }
    .close-btn {
        border: 0;
        background-color: transparent;
        @include position(var(--space-2), var(--space-2));
        @extend %font-0;
        &::before {
            @include icon(close, 12);
        }
    }
}
@include mq(col-md){
    .cookie-block {
        .cookie-wrap {
            width: 35rem;
            @include position(null, var(--space-8), var(--space-8), unset);
        }
    }
}